.button {
  display: inline-flex;
  position: relative;
  padding: 6px 15px;
  @include f-muller-bold;
  font-size: 2.8125vw;
  @include adaptive-size('font-size', 15, $hasLimit: true);
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.6px;
  line-height: 1;

  @include sm-up {
    padding: 4px 8px; }

  @include cont() {
    height: 1px;
    width: pixel-calc(30, 40, $desktop-point);
    left: 100%;
    top: 50%;
    margin-left: 6.22%;
    background-color: inherit;

    @include xs-only {
      width: 10vw;
      height: 2px;
      left: calc(100% + 11px);
      margin: 0; }

    @include breakpoint($max-width) {
      width: 40px;
      margin-left: 7px; }

    @include sm-up {
      transform: translateY(-50%); } }

  &--big {
    display: flex;
    padding: 8px 16px 5px;
    @include adaptive-size('font-size', 20, $hasLimit: false); }

  &--black {
    background-color: #1e0e00;
    color: var(--gray-lightest) !important; }

  &--white {
    background-color: var(--gray-lightest);
    color: #1e0e00 !important; } }
