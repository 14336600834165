.vision {
  --section-bg: var(--blue);
  --title-color: #1e0e00;
  --text-color: var(--gray-lightest);

  padding: 0;
  @include xs-only {
    background-color: var(--section-bg); }
  @include sm-up {
    padding-top: calc(var(--cell-y)*4);
    height: calc(var(--cell-y)*30);
    background-color: white; }

  .scroll-bar-background {
    z-index: z('default'); }

  &:not(&--portrait) &__content {
    z-index: z('default') + 1;
    @include sm-up {
      @include cont() {
        width: 2px;
        height: 100%;
        top: 0;
        left: calc(var(--cell-x)*3);
        transform: translateX(-1px);
        background-color: var(--text-color); } } }

  &--portrait {
    --section-bg: var(--light-bg-color);
    --text-color: #1e0e00;
    --bg-letter-color: #c1a192;
    --bg-letter-opacity: 1;
    --bg-letter-top: calc(var(--cell-y)*1.3);
    --content-hieght-cells: 20; }

  &__title {
    color: var(--title-color);
    @extend %mission-page-title;
    @include sm-up {
      position: absolute;
      margin-left: calc(var(--cell-x)*4); } }

  &__content {
    position: relative;
    background-color: var(--section-bg);
    color: var(--text-color);
    z-index: z('default');
    padding: 31.25vw calc(var(--cell-x)*2);

    @include sm-up {
      margin-top: calc(var(--cell-y)*3);
      min-height: calc(var(--cell-y)*var(--content-hieght-cells, 18);
      padding: calc(var(--cell-y)*5) calc(var(--cell-x)*2) calc(var(--cell-y)*5) calc(var(--cell-x)*6); } }

  &--portrait &__content {
    @include sm-up {
      padding: calc(var(--cell-y)*8) calc(var(--cell-x)*1) calc(var(--cell-y)*4) calc(var(--cell-x)*7); } }

  &__bg-letter {
    @extend %bg-letter;
    @extend %mission-page-bg-letter;
    @include bg-letter-mixins;
    z-index: z('default');
    @include sm-up {
      left: calc(var(--cell-x)*3.75); } }

  .section__subtitle, .section__desc {
    position: relative;
    z-index: z('default') + 1; }

  &__image {

    @include sm-up {
      position: absolute;
      height: calc(var(--cell-y)*var(--image-hieght-cells, 26);
      img {
        height: 100%;
        object-fit: cover; } } } }
