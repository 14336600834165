.page-banner {
  --section-bg: var(--gray-lightest);
  display: flex;
  align-items: flex-end;
  width: 100%;
  position: relative;
  overflow: hidden;
  z-index: z('banner') - 1;
  height: 100vh;
  background-color: var(--section-bg);

  @include sm-up {
    height: 0;
    padding-top: 56.25%; }
  @include md-up {
    @include orient-land() {
      max-height: 1080px;
      height: calc(var(--cell-y) * 30);
      padding: 0; } }

  &__image {
    width: auto;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: z('below');

    @include sm-up {
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%); }

    @include md-up {
      @include orient-land() {
        height: inherit; } }

    @include xs-only {
      &.mid {
        left: 50%;
        transform: translateX(-50%); }
      .team-page & {
        left: -120%; }
      .succession-page & {
        left: -40%; }
      .history-page & {
        left: -90%; }
      .contacts-page & {
        transform: translateX(-50%); } } }

  &__letter {
    position: absolute;
    right: 0;
    top: 0;
    bottom: -1px;
    height: calc(100% + 1px);
    max-height: calc(100% + 1px);
    width: auto;
    max-width: 100%;

    @include xs-only {
      display: none; }

    &--contacts {
      left: calc( var(--cell-x) * 3); }
    &--team {
      left: calc( var(--cell-x) * -1); } }

  &__text {
    color: var(--gray-lightest);
    @include f-vogue;
    font-weight: 400;
    letter-spacing: pixel-calc(0,2);
    line-height: 1.3;
    text-transform: uppercase;
    @include adaptive-size('font-size', 50, $hasLimit: false);

    @include xs-only {
      display: flex;
      align-items: center;
      min-height: 50vw;
      padding: 25vw calc(var(--cell-x)*2);
      font-size: 7.5vw;

      &--portrait {
        align-self: flex-start;
        font-size: 15vw;
        [lang="ru"] & {
          font-size: 16.875vw; } }
      &--team {
        min-height: 85%; }
      &--succession {
        height: 80vw; }
      &--history {
        font-size: 16.25vw; }
      &--contacts {
        font-size: 13.75vw;
        align-self: flex-start; } }

    @include sm-up {
      top: 77%;
      position: absolute;
      left: calc( var(--cell-x) * 3); }

    @include md-up {
      left: calc( var(--cell-x) * 4);

      &--invest,
      &--succession {
        @include orient-land() {
          top: calc( var(--cell-y) * 21.5); }
        [lang="ru"] & {
          @include adaptive-size('font-size', 40, $hasLimit: false); } }

      &--benefits {
        top: unset;
        bottom: calc(var(--cell-y)*2); }

      &--mission {
        top: calc( var(--cell-y) * 21); }

      &--contacts {
        top: calc( var(--cell-y) * 4);
        left: calc( var(--cell-x) * 16);
        @include adaptive-size('font-size', 61, $hasLimit: false); }

      &--team {
        left: calc( var(--cell-x) * 15);
        [lang="ru"] & {
          left: calc( var(--cell-x) * 17); } }

      &--portrait, &--history {
        top: calc( var(--cell-y) * 3);
        @include adaptive-size('font-size', 100, $hasLimit: false); } } } }
