.investp {
  background-color: var(--gray-lightest);

  @include sm-up {
    padding-top: calc(var(--cell-y) * 3);
    padding-bottom: calc(var(--cell-y) * 3);

    .succession-page & {
      padding-top: calc(var(--cell-y) * 4); } }

  .contacts-page & {
    background-color: #efeae9; }

  &__content {
    display: flex;
    flex-direction: column;
    margin: 0 auto; }

  &__info-block {
    color: var(--blue);
    &--light-text {
      color: var(--blue-light); } }

  &__info-block + &__navigation {
    padding-top: calc(var(--cell-y) * 1.6); }

  [lang="ru"] &__info-block + &__navigation {
    padding-top: calc(var(--cell-y) * 1); }

  [lang="ru"] &__info-block + &__navigation--shift {
    @include sm-up {
      padding-top: calc(var(--cell-y) * 3); } }

  &__navigation {
    margin: auto 0;
    @include xs-only {
      margin: 3.125vw auto -3.125vw;
      width: 60vw; } }

  &__title {
    @include f-vogue;
    font-weight: 500;
    line-height: 1.15;
    text-transform: uppercase;
    margin-bottom: 12.5vw;
    @include xs-only {
      font-size: 7.5vw; }
    @include sm-only {
      br {
        display: none; } }

    @include adaptive-size('margin-bottom', 48, $hasLimit: true);
    @include adaptive-size('font-size', 100, $hasLimit: false); }

  &__desc {
    line-height: 1.37;
    @include lg-up {
      font-size: pixel-calc(15, 20, $desktop-point); } } }
