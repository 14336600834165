.fullscreen {
  --section-bg: var(--blue);
  display: flex;
  color: var(--gray-lightest);
  z-index: z('default');

  @include xs-only {
    flex-direction: column;
    padding: 0;
    &--assets, &--real-estate, &--portrait,
    &--mission, &--history,
    &--monitoring, &--insurance {
      min-height: 100vh;
      align-items: center;
      justify-content: center; } }

  @include sm-up {
    justify-content: flex-end;
    &:not(&--with-color-line):not(&--external):not(&--partners) {
      padding-top: calc(var(--cell-y)*var(--pad-y-cells, 2));
      padding-bottom: calc(var(--cell-y)*2); }
    &--mission, &--portrait {
      padding-bottom: 0!important; } }

  @include orient-land() {
    min-height: 64vh; }

  &--assets {
    --pad-y-cells: 3.5;
    background: url("../images/invest-portfel/management3.jpeg") center/cover no-repeat;
    @include xs-only {
      background-position: 25% top; } }

  &--consulting {
    background-color: var(--section-bg);

    @include sm-up {
      .fullscreen__bg-img-container {
        @include cont($isAfter: false, $full: true) {
          background-color: rgba(#001326, 0.36);
          z-index: 1; } }

      @include cont($isAfter: true, $full: true) {
        background: linear-gradient(to right, transparent 26%, rgba(0,0,0,0.67) 100%);
        z-index: auto; } } }

  &--real-estate {
    background: #7f4315 url("../images/invest-portfel/nedvizhimost2.jpeg") 27%/cover no-repeat;
    @include sm-up {
      background-position: center;
      padding-top: pixel-calc(213, 285, $desktop-point)!important; } }

  &--monitoring {
    --pad-y-cells: 8;
    background: url("../images/maintenance/monitoring-reporting.jpeg") center/cover no-repeat;
    @include xs-only {
      background-position: left top;
      @include cont($full:true) {
        background-color: var(--brown);
        mix-blend-mode: multiply;
        opacity: 0.4; } }
    @include sm-up {
      padding-bottom: calc( var(--cell-y) * 4); } }

  &--insurance {
    --pad-y-cells: 5;
    background: url("../images/maintenance/insurance.jpeg") center/cover no-repeat;
    @include xs-only {
      background-position: 72% center; }
    .grid__row {
      height: 100%;
      align-items: center; } }

  &--collections, &--reliability {
    --section-bg: var(--beige-lighter);
    background-color: var(--section-bg);
    color: var(--primary-text-color);
    @include sm-up {
      padding-top: calc(var(--cell-y)*var(--pad-y-cells)); } }

  &--collections {
    --pad-y-cells: 6;
    [lang="ru"] {
      --pad-y-cells: 4; } }

  &--reliability {
    --pad-y-cells: 2;
    position: relative; }

  &--convenience {
    --pad-y-cells: 5;
    min-height: 100vh;
    background: url("../images/benefits-principles/comfort-2.jpeg") center/cover no-repeat;
    @include xs-only {
      background-position: 74% center; } }

  &--portrait {
    background: url("../images/portrait/portrait-bg.jpeg") center/cover no-repeat;
    color: var(--gray-lightest);
    @include xs-only {
      background-position: 30% center; } }

  &--mission {
    --pad-y-cells: 7;
    [lang="ru"] {
      --pad-y-cells: 5; }

    background: url("../images/mission/reputation-bg.jpeg") center/cover no-repeat;
    color: var(--primary-text-color);

    @include xs-only {
      background-position: 70% center; } }

  &--team {
    --pad-y-cells: 18;
    --section-bg: var(--brown-light);
    background-color: var(--section-bg);
    color: var(--gray-lightest); }

  &--partners {
    --pad-y-cells: 18;
    background: url("../images/partners/partners-bg.webp") center/cover no-repeat;
    color: var(--gray-lightest);
    .no-webp & {
      background: url("../images/partners/partners-bg.jpg") center/cover no-repeat; } }

  &--history {
    background: center/cover no-repeat;
    color: var(--gray-lightest); }

  &--history-13 {
    background-image: url("../images/history/year-2013.jpg"); }

  &--history-16 {
    background-image: url("../images/history/year-2016.jpg"); }

  &__bg-img-container {
    position: relative;
    width: 100%;

    @include xs-only {
      @include ratio-img(3, 4); }

    @include sm-up {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      z-index: 0;
      overflow: hidden; }

    img {
      min-height: 100%;
      object-fit: cover; } }

  &__list {
    margin-top: 10vw;
    @include sm-up {
      margin-top: 20px; } }

  &__info {
    z-index: z('default');
    @include xs-only {
      padding: 25vw calc(var(--cell-x) * 2);
      &.no-p {
        padding: 0 calc(var(--cell-x) * 2); } }

    @include sm-up {
      padding: var(--cell-y) 0; }

    .section__desc {
      &:first-child {
        margin-top: 0; } } }

  @include sm-up {
    &--monitoring &__info {
      padding: 0; }
    &--mission &__info,
    &--portrait &__info {
      padding-top: 0; }
    &--history &__info {
      padding-top: calc(var(--info-y-offset-cells) * var(--cell-y)); } }

  &__color-line {
    @extend .visible-sm-up;
    background-color: var(--blue-light);
    width: calc( var(--cell-x) * 4);
    height: 100%; }

  &--with-color-line {
    overflow: hidden;
    .grid__row {
      height: 100%; } }

  &--real-estate &__alfa-text {
    @include md-up {
      font-size: pixel-calc(262, 350, $desktop-point); } }

  &__container {
    display: flex;
    flex-direction: column;
    width: 100%; }

  /* For fullscreen sections with __external-links */
  &__container &--reliability,
  &__container &--reputation {
    flex: 1; }

  &--portrait &--reputation {
    align-items: center; }

  &__external-links {
    display: flex;
    height: calc( var(--cell-y) * 5);
    padding: var(--cell-y) calc( var(--cell-x) * 2);
    background-color: var(--brown-lighter);
    color: #1e0e00; }

  &__link {
    color: inherit; }

  &__link-desc {
    @include adaptive-size('font-size', 20, $hasLimit: false);
    @include adaptive-size('margin-top', 19, $hasLimit: false);
    line-height: 1.3; }

  &--mission &__external-links {
    padding-left: 0;
    padding-right: 0;
    background-color: #915333;
    color: var(--gray-lightest);
    .fullscreen__link {
      width: auto;
      flex-basis: auto; }
    .fullscreen__link + .fullscreen__link {
      padding-left: pixel-calc(44, 59, $desktop-point); } }

  &--portrait &__external-links {
    background-color: #915333;
    color: var(--gray-lightest); } }
