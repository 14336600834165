 :root {
  --cell-x: calc(100vw / 24);
  --cell-y: 36px;

  @include orient-land() {
    --cell-y: calc(100vh / 30); } }

$grid-breakpoints: $breakpoints;
$grid-gutter-breakpoints: (xs: 4%, md: 'auto');

$max-width: $max-screen-width + px;
$columns: 24;

@function breakpoint($breakpoint) {
  @if map-has-key($grid-breakpoints, $breakpoint) {
    @return map-get($grid-breakpoints, $breakpoint); }
  @else {
    @warn "breakpoint: could not find #{$breakpoint} in $grid-breakpoints map. Please make sure it is defined"; } }

@function grid-gutter($breakpoint) {
  @if map-has-key($grid-gutter-breakpoints, $breakpoint) {
    @return map-get($grid-gutter-breakpoints, $breakpoint); }
  @else {
    @warn "grid-gutter: could not find #{$breakpoint} in $grid-breakpoints map. Please make sure it is defined"; } }

.grid {
  width: 100%;
  min-height: 1px;
  &__row {
    display: flex;
    flex-direction: row;
    width: 100%;
    @include xs-only {
      flex-wrap: wrap; }

    &.fullheight {
      height: 100%; } }

  @include sm-up {
    margin-left: unquote(grid-gutter("md"));
    margin-right: unquote(grid-gutter("md"));
    width: 100%; } }

[class*='col-'] {
  position: relative;
  width: 100%;
  min-height: 1px; }

@for $i from 1 through $columns {
  .col-xs-#{$i} {
    width: calc( var(--cell-x) * #{$i});
    flex-basis: calc( var(--cell-x) * #{$i}); }
  .col-xs-offset-#{$i} {
    margin-left: calc( var(--cell-x)* #{$i}); } }

@each $breakpoint in map-keys($grid-breakpoints) {
  @media (min-width: breakpoint($breakpoint)) {
    .col-#{$breakpoint}-auto {
      flex: 0 0 auto;
      width: auto; }

    @for $i from 1 through $columns {
      .col-#{$breakpoint}-#{$i} {
        width: calc( var(--cell-x) * #{$i});
        flex-basis: calc( var(--cell-x) * #{$i}); }

      .col-#{$breakpoint}-offset-#{$i} {
        margin-left: calc( var(--cell-x) * #{$i}); } }

    .hidden-#{$breakpoint}-up {
      @include hidden; } }

  @media (max-width: breakpoint($breakpoint)) {
    .visible-#{$breakpoint}-up {
      background: red;
      @include hidden; } } }

@mixin column-size($col-num) {
  width: 100% * $col-num / $columns;
  flex-basis: 100% * $col-num / $columns;
  max-width: 100% * $col-num / $columns; }

.col-xs, .col-sm, .col-sm, .col-lg, .col-xl {
  flex-basis: 0;
  flex: 1;
  flex-grow: 1;
  max-width: 100%; }
