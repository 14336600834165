.article {
  flex-direction: column;
  padding: 20vw calc(var(--cell-x)*2);
  @include md-up {
    padding: calc(var(--cell-y)*3) 0 0;
    @include adaptive-size('margin-bottom', 160, $hasLimit: true); }

  .back-link {
    color: inherit;
    @include adaptive-size('font-size', 23, $hasLimit: true);
    @include breakpoint-max($md-point) {
      margin-top: 5vw; } }

  &__title, &__quote {
    text-transform: uppercase;
    font-weight: 400;
    @include f-21-cent; }

  &__title {
    font-size: 4.6875vw;
    line-height: 1.25;
    margin: 0.75rem 0 2.5rem;
    @include md-up {
      margin-top: var(--cell-y);
      margin-bottom: calc(var(--cell-y)*4);
      @include adaptive-size('font-size', 60, $hasLimit: true); } }

  p {
    font-size: 18px;
    @include adaptive-size('font-size', 23, $hasLimit: true);
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0; } }

  &__letter {
    display: none;
    color: var(--beige);
    @extend %bg-letter;
    @include bg-letter-mixins();
    left: 0;
    z-index: 1;
    @include md-up {
      display: block;
      left: calc( var(--cell-x) * 3.6);
      top: calc(var(--cell-y) * -2);
      z-index: 0;
      @include adaptive-size('font-size', 436); } }

  &__text-bg {
    position: relative;
    background-color: var(--beige-lighter);
    margin: 3rem calc(var(--cell-x)*-2);
    padding: right calc(var(--cell-x)*2);
    padding: 3rem calc(var(--cell-x)*2);
    @include md-up {
      margin: 0;
      padding: calc(var(--cell-y)*2) calc(var(--cell-x)*6);
      padding-right: calc(var(--cell-x)*7); }

    p {
      position: relative;
      z-index: 2; } }

  &__text-default {
    @include md-up {
      padding-left: calc(var(--cell-x)*6);
      padding-right: calc(var(--cell-x)*7);
      margin: calc(var(--cell-y)*2) 0; } }

  &__quote {
    padding: 3rem calc(var(--cell-y)*1);
    margin: 3rem calc(var(--cell-y)*-1);
    line-height: 1.33;
    border-style: solid;
    border-width: 2px 0;
    border-color: #002230;
    @include md-up {
      padding: 56px 40px;
      margin: 0 calc(var(--cell-x)*7) 0 calc(var(--cell-x)*6); }

    p {
      font-size: 4.6875vw;
      @include adaptive-size('font-size', 30, $hasLimit: true); } }

  &__image {
    height: 200px;
    width: calc(100vw - calc(var(--cell-x)*2));
    margin-right: calc(var(--cell-x)*-2);
    overflow: hidden;
    position: relative;
    img {
      position: absolute;
      object-fit: cover;
      top: 50%;
      object-position: center center;
      transform: translateY(-50%); }

    @include md-up {
      position: absolute;
      padding-top: 0;
      right: 0;
      top: 12%;
      bottom: 0;
      height: 88%;
      width: calc(var(--cell-x)*5);
      z-index: 1;
      img {
        height: 100%;
        object-position: left center; } } } }
