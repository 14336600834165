.partners {
  @include xs-only {
    padding-top: 20vw;
    padding-bottom: 20vw;
    min-height: 100vh;
    justify-content: center;
    .fullscreen__info {
      padding-top: 10vw;
      padding-bottom: 10vw; } }

  &__logos-container {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 5vw 10vw;
    align-items: center;

    @include sm-up {
      grid-auto-rows: 1fr;
      grid-gap: var(--cell-y) var(--cell-x);
      margin-top: calc(var(--cell-y) * 7); }

    @include md-up {
      grid-template-columns: repeat(3, calc(var(--cell-x) * 2)); }

    > img {
      width: auto;
      max-width: 20.625vw;
      @include sm-up {
        max-height: calc(var(--cell-y) * 2);
        max-width: 100%; } } }

  .swiper-slide {
    @include xs-only {
      align-items: center;
      justify-content: center; } }

  .swiper-container {
    padding: 20.625vw 0 0 0;
    @include sm-up {
      padding: 0 0 calc(var(--cell-y) * 1.5) 0; } }

  .text-swiper-pagination {
    top: calc(var(--cell-y) * 1);
    @include sm-up {
      top: calc(var(--cell-y) * 4);
      padding-left: calc(var(--cell-x) * 0.5); } }

  .text-swiper-buttons {
    top: calc(var(--cell-y) * 3);
    right: var(--cell-y);
    height: calc(var(--cell-y) * 2); } }
