.mission {
  --section-bg: var(--blue-light);
  --text-color: var(--gray-lightest);

  color: var(--text-color);
  padding: 0;
  overflow: hidden;
  @include xs-only {
    background-color: var(--section-bg); }
  @include sm-up {
    height: calc(var(--cell-y)*30);
    background-color: var(--gray-lightest); }

  &--portrait {
    --section-bg: var(--brown-light);
    --content-pt-cells: 8; }

  &__title {
    @extend %mission-page-title;
    @include sm-up {
      margin-bottom: calc(var(--cell-y)*2); } }

  &__content {
    background-color: var(--section-bg);
    z-index: z('default');
    padding: 25vw calc(var(--cell-x)*2);

    @include sm-up {
      min-height: calc(var(--cell-y)*var(--content-hieght-cells, 27);
      padding: calc(var(--cell-y)*3) calc(var(--cell-x)*3) calc(var(--cell-y)*4) calc(var(--cell-x)*5);

      .mission-page & .section__desc {
        padding-top: calc(var(--cell-y)*4);
        padding-left: calc(var(--cell-x)*2); } } }

  &--portrait &__content {
    @include sm-up {
      padding: calc(var(--cell-y)*6) calc(var(--cell-x)*5) calc(var(--cell-y)*4) calc(var(--cell-x)*5); } }

  &__bg-letter {
    @extend %bg-letter;
    @extend %mission-page-bg-letter;
    @include bg-letter-mixins;
    @include sm-up {
      --bg-letter-top: calc(var(--cell-y)*5.5);
      transform: translateX(-2%);
      left: unset; } }

  &__image {
    bottom: 0;
    z-index: z('default') + 1;

    @include xs-only {
      @include ratio-img(3, 4); }

    @include sm-up {
      position: absolute;
      top: calc(var(--cell-y)*3); }

    img {
      height: 100%;
      object-fit: cover; } } }
