.two-sections {

  section, .section {
    --section-bg: transparent;
    overflow: hidden; }

  &--structuring-page {
    background: url('../images/structuring.jpeg') center/cover no-repeat;
    @include xs-only {
      background-size: auto 60%;
      background-position: left 0%;
      .all-services--structuring {
        padding-bottom: 30vw; }
      .all-services--criteria {
        --section-bg: var(--beige-lighter);
        overflow: visible;
        @include cont() {
          @include triangle(8.125vw, var(--section-bg), 'up'); } } } }

  &--benefits-page {
    background: url('../images/benefits-principles/independence-2.jpeg') center/cover no-repeat;
    @include xs-only {
      background-size: auto 70%;
      background-position: 55% -20%;
      .all-services:first-child {
        padding: 25vw 0; } } }

  &--mission-page {
    background: url('../images/mission/people-bg.jpeg') center/cover no-repeat;
    @include xs-only {
      background-size: auto 70%;
      background-position: top right;
      section {
        overflow: visible !important; } } }

  &--history-page {
    @include xs-only {
      .alt-invest--fullscreen {
        min-height: 100vh;
        align-items: center;
        background: url('../images/history/year-2020.jpg') left bottom/cover no-repeat;
        z-index: -1; }

      .alt-invest--history {
        overflow: visible; } }

    @include sm-up {
      background: url('../images/history/year-2020.jpg') center bottom/cover no-repeat; } }

  &__info-block {
    position: relative;
    padding-left: 10.1045%;
    padding-right: 11.146%;
    background-color: var(--beige-lighter);
    @include cont() {
      @include triangle(pixel-calc(36, 48, $desktop-point), var(--beige-lighter), 'left'); } }

  &__text-block {
    padding-left: calc(var(--cell-x) * 2);
    padding-right: calc(var(--cell-x) * 2);
    & + & {
      margin-top: 25vw; }
    @include sm-up {
      padding: 0;
      margin-top: calc(var(--cell-y) * 5);
      & + & {
        margin-top: calc(var(--cell-y) * 4); } } } }
