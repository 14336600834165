.side-nav {
  display: flex;
  flex-direction: column;
  @include f-muller-med;
  font-size: pixel-calc(10, 12, $desktop-point);
  text-transform: uppercase;
  text-size-adjust: none!important;

  &__link {
    color: inherit;
    line-height: 1.3; }
  &__link + &__link {
    @include adaptive-size('margin-top', 16, $hasLimit: false); } }
