.timeline {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &__swiper {
    display: flex;
    position: relative;
    margin-left: calc(var(--cell-x) * 2);
    width: calc(100% - calc(var(--cell-x) * 2));
    overflow: visible;
    @include md-up {
      margin-left: 0;
      width: 100%;
      padding: 0 5%; } }

  &__scrollbar {
    display: none;
    position: absolute;
    left: 0;
    top: -4px;
    height: 4px;
    width: 100%;
    transform: translateY(-200%);

    @include cont($full: true) {
      background-color: var(--brown-dark);
      transform: translateY(200%); }

    @include md-up {
      display: block;
      &-drag {
        border-radius: unset;
        height: 20px;
        background-color: var(--brown-dark); } } }

  &__item {
    width: calc(47.5vw + 12px);
    margin-right: 17vw;
    @include md-up {
      width: 22%;
      margin-right: 50px;
      padding: 0 pixel-calc(20, 30, $desktop-point); } }

  &__item,
  &__button-prev,
  &__button-next {
    @include adaptive-size('margin-top', -75, -65, $hasLimit: true); }

  &__button-prev,
  &__button-next {
    position: absolute;
    top: 0;
    border: none;
    cursor: pointer;
    display: none;
    z-index: 10;
    &.swiper-button-disabled {
      opacity: 0;
      visibility: hidden;
      pointer-events: none; }
    @include md-up {
      display: flex; } }

  &__button-prev {
    left: 0;
    right: unset;
    @include cont() {
      background-color: white;
      @include triangle(pixel-calc(19, 26, $desktop-point), var(--brown-dark), 'left');
      transform: translateX(50%); } }
  &__button-next {
    right: 0;
    left: unset;
    @include cont() {
      background-color: white;
      @include triangle(pixel-calc(19, 26, $desktop-point), var(--brown-dark), 'right');
      left: 93%;
      transform: translateX(-100%); } } }
