.text-swiper-buttons,
.text-swiper-pagination {
  position: absolute;
  z-index: z('above'); }

// Left navigation offset sets in html file for different slides

.text-swiper-buttons {
  @include xs-only {
    display: none; }
  top: calc( var(--cell-y) * 8);
  width: 2px;
  @include adaptive-size('height', 72, $hasLimit: false);
  background-color: var(--swiper-button-color);

  > * {
    position: absolute;
    cursor: pointer;

    &:focus {
      outline: none;
      opacity: 0.3; }

    &.swiper-button-disabled {
      display: none; } } }

.text-swiper-button-next {
  @include triangle(pixel-calc(11, 15, $desktop-point), var(--swiper-button-color), 'right');
  @include adaptive-size('left', 19, $hasLimit: false); }

.text-swiper-button-prev {
  @include triangle(pixel-calc(11, 15, $desktop-point), var(--swiper-button-color), 'left');
  @include adaptive-size('right', 19, $hasLimit: false);
  left: unset; }

.text-swiper-pagination {
  display: flex;
  bottom: 100vw !important;
  @include xs-only {
    .full-height &,
    .xs-no-img & {
      bottom: 25vw!important; } }

  @include xs-only {
    width: auto;
    left: 50%;
    transform: translateX(-50%); }

  @include sm-up {
    bottom: calc( var(--cell-y) * 3) !important; }

  .swiper-pagination-bullet {
    position: relative;
    display: block;
    width: 2.1875vw;
    height: 2.1875vw;
    @include adaptive-size('height', 14, $hasLimit: true);
    @include adaptive-size('width', 14, $hasLimit: true);
    background-color: var(--swiper-button-color);
    opacity: 1;
    margin-right: 0;
    margin-left: 0;
    transform: translate(-50%, -50%);

    & + .swiper-pagination-bullet {
      margin-left: 10vw;
      @include sm-up {
        margin-left: calc(var(--cell-x) * 0.825); } }

    @include cont() {
      @include xs-only {
        @include size(9.375vw); }
      @include circle(pixel-calc(42, 58));
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: transparent;
      border: 1px solid var(--swiper-button-color);
      opacity: 0;
      transition: .2s opacity, .2s background-color; }

    &:focus {
      outline: none;

      &::after {
        background-color: var(--swiper-button-color);
        opacity: 0.25; } }

    &-active::after {
      opacity: 1; } } }
