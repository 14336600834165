$opacityArray: [10, 20, 30, 40, 60];
$fontsArray: [300, 320, 340, 350, 390, 450];

.alfa-text {
  position: absolute;
  @include f-vogue;
  font-weight: 500;
  white-space: nowrap;
  line-height: 1.05;
  @include user-select(none);

  @extend .visible-sm-up;

  &:not(&--rotated) {
    bottom: 0;
    z-index: z('default'); }

  &--rotated {
    display: block;
    right: 0;
    transform-origin: center;
    transform: rotate(180deg);
    writing-mode: vertical-lr;
    overflow: hidden;
    z-index: z('above');
    bottom: calc(var(--cell-y) * 2);
    right: 2%; }

  &--light {
    color: var(--beige-light); }
  &--white {
    color: var(--gray-lightest); }
  &--dark {
    color: #1e0e00; }
  &--blue {
    color: var(--blue); } }

@each $opacity in $opacityArray {
  .alfa-#{$opacity} {
      opacity: $opacity / 100; } }

@each $fontSize in $fontsArray {
  .f-#{$fontSize} {
    @include adaptive-size('font-size', $fontSize, $hasLimit: false); } }

// positioning
[class*="--benefits-page"] .alfa-text--rotated {
  @include sm-up {
    letter-spacing: -12px; } }

[class*="--maintenance"],
[class*="--portrait"],
[class*="--history"] {
  .alfa-text--rotated {
    bottom: calc(var(--cell-y) * 1);
    right: 2%; } }

[class*="--collections"] .alfa-text {
  left: calc( var(--cell-x) * 2);
  overflow: hidden; }

[class*="--long-term"] .alfa-text {
  left: calc(var(--cell-x) * 4.5); }

[class*="--reliability"] .alfa-text {
  left: calc( var(--cell-x) * 4); }

[class*="--investment"] .alfa-text {
  left: calc( var(--cell-x) * 3);
  bottom: calc( var(--cell-y) * 30); }

[class*="--monitoring"] .alfa-text {
  left: calc( var(--cell-x) * 3); }

[class*="--assets"],
[class*="--real-estate"] {
  .alfa-text {
    left: -5%;
    bottom: calc( var(--cell-y) * 1); } }

[class*="--structuring"] .alfa-text {
  left: 1.5%;
  letter-spacing: -10px;
  line-height: 1; }

[class*="succession"] .alfa-text {
  left: calc(var(--cell-x) * 2);
  line-height: 0.9;
  mix-blend-mode: overlay; }
