.services-by-asset-types {
  $border-thick: 2px;
  $border-color: #c9afa4;

  flex-direction: column;

  @include sm-up {
    width: calc( var(--cell-x) * 17);
    grid-template-columns: repeat(17, 1fr);

    & > * {
      margin-left: calc( var(--cell-x) * 0.5); } }

  &__title {
    grid-area: 5 / 3 / 7 / 11;
    @include sm-up {
      margin-right: 10px; } }

  &__column {
    grid-area: 9 / 2 / 30 / 8;
    @include xs-only {
      order: 2; } }

  &__column + &__column {
    grid-area: 9 / 8 / 30 / 14;
    @include xs-only {
      order: 1; } }

  &__list {
    @include adaptive-size('margin-left', 30, $hasLimit: true);
    @include sm-up {
      @include adaptive-size('margin-top', 40, $hasLimit: false); } } }
