.services {

  $border-thick: 2px;
  $border-color: #c9afa4;

  display: flex;
  flex-direction: column;

  @include xs-only {
    padding: 25vw calc( var(--cell-x) * 2); }

  @include sm-up {
    height: 100vh;
    display: grid;
    grid-template-rows: repeat(30, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px; }

  a {
    color: inherit; }

  &__title {
    position: relative;
    margin: 0 calc( var(--cell-x) / 2);
    text-align: center;
    @include xs-only {
      font-size: 7.5vw; }
    @include sm-up {
      border-bottom: $border-thick solid $border-color;
      @include cont($bothSides: true) {
        top: 100%;
        width: $border-thick;
        min-width: $border-thick;
        max-width: $border-thick;
        height: pixel-calc(13, 18);
        background-color: $border-color; }
      &::before {
        left: 0; }
      &::after {
        right: 0; } } }

  &__column {
    @include xs-only {
      margin-top: 14vw; } }

  &__list {
    text-transform: uppercase;
    margin-top: 8.125vw;
    @include sm-up {
      margin-top: calc( var(--cell-y) * 0.8); }

    li {
      @include sm-up {
        display: grid; }

      &.offset {
        margin-top: pixel-calc(21, 28, $desktop-point); }

      @include xs-only {
        &.list__item {
          margin-top: 4.6875vw;
          ul > li {
              margin-top: 0.9em; } } } }

    > li + li {
      @include adaptive-size('margin-top', 15, $hasLimit: false); }

    a {
      display: block;
      font-size: 4.0625vw;
      @include adaptive-size('font-size', 20, $hasLimit: true);
      font-weight: 400;
      line-height: 1.32;
      &:hover {
        font-weight: 500; }
      @include xl-up {
        margin-top: -2px; } }

    // equal
    ul {
      position: relative;
      margin-top: 5px;
      padding-top: 7px;
      padding-bottom: 17px;
      padding-left: 3.75vw;
      @include adaptive-size('padding-left', 30, $hasLimit: true);
      text-transform: none;
      border-left: $border-thick solid $border-color;
      li {
        @include adaptive-size('margin-top', 20, $hasLimit: false); }
      @include cont() {
        bottom: 0;
        left: 0;
        width: pixel-calc(13, 18);
        min-height: $border-thick;
        max-height: $border-thick;
        height: $border-thick;
        background-color: $border-color; }
      a {
        line-height: 1;
        margin-top: 0; } } }
  br {
    display: none;
    @include sm-up {
      display: inline; } } }
