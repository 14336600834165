.wwd-slide {
  --mob-img-width: calc(var(--cell-x) * 4);

  display: flex;
  position: relative;
  color: #1e0e00;

  @include orient-land() {
    &:not(&--4) {
      height: 100vh !important; } }

  &--2,
  &--4 {
    flex-direction: row-reverse; }

  @include breakpoint-max($md-point) {
    --mob-pt: 12.5vw;
    --mob-pb: 10vw;

    padding-top: var(--mob-pt);
    padding-bottom: var(--mob-pb); }

  @include md-up {
    padding-top: calc( var(--cell-y) * 7);

    &:not(&--4) {
      padding-left: calc(var(--cell-x) * 4);
      padding-right: calc(var(--cell-x) * 1); }
    &--4 {
      padding-top: calc( var(--cell-y) * 2);
      height: auto; } }

  &__heading {
    display: none;
    position: absolute;
    @include f-muller-reg;
    font-weight: 400;
    text-transform: uppercase; }

  &__img-container {
    position: absolute;
    height: calc(100% - var(--mob-pt));
    top: var(--mob-pt);
    width: var(--mob-img-width);
    z-index: z('default');

    img {
      height: 100%;
      object-fit: cover; } }

  &--2 &__img-container {
    --topimg2: calc(var(--mob-pt) * 2.4);
    top: calc(var(--mob-pt) * 2.4);
    height: calc(100% - var(--topimg2) - var(--mob-pb)); }

  @include md-up {
    &__heading {
      display: block;
      top: pixel-calc(64, 86, $desktop-point);
      left: calc(var(--cell-x) * 4);
      font-size: pixel-calc(21, 29, $desktop-point); }

    &--2 &__heading {
      left: calc(var(--cell-x) * 14); }

    &__img-container {
      position: relative;
      height: -webkit-fill-content;
      height: fit-content;
      width: calc( var(--cell-x) * 9);

      @include cont($full: true) {
        background-image: linear-gradient(0deg, #000, rgba(#000, 0) 85%);
        opacity: 0.5;
        transform: translateX(calc(var(--cell-x) / 2)) translateY(var(--cell-y));
        z-index: z('default') + 1; } }

    &--1 &__img-container {
      height: calc( var(--cell-y) * 26); }

    &--2 &__img-container {
      margin-top: calc( var(--cell-y) * 3);
      width: calc( var(--cell-x) * 10);

      &::after {
        transform: translate(pixel-calc(-30, -40, $desktop-point), pixel-calc(27, 36, $desktop-point)); } }

    &--4 &__img-container {
      height: calc(var(--cell-y) * 16);
      img {
        max-height: 100%;
        object-fit: cover; }
      &::after {
        content: none; } } }

  .wwd-slide__text {
    position: absolute;
    display: none;
    width: 0;

    @include md-up {
      display: block;
      top: 50%;
      @include adaptive-size('left', 80, $hasLimit: false);
      width: 60%;
      margin: auto;
      transform: translateY(-50%);
      z-index: z('above') + 1;
      color: var(--gray-lightest);
      @include body-text;

      ul {
        padding-left: pixel-calc(27, 36, $desktop-point); } } }

  &__content {
    position: relative;
    padding-left: calc(var(--cell-x) * 2 + var(--mob-img-width));
    width: 100%;
    @include breakpoint-max($md-point) {
      padding-bottom: 11.25vw;
      padding-right: calc(var(--cell-x)*2); }
    @include md-up {
      padding-left: var(--cell-x);
      width: calc(var(--cell-x) * 9); } }

  &__title {
    font-size: 7.5vw;
    @include f-vogue;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1.17;

    @include breakpoint-max($md-point) {
      margin-bottom: 15vw; }

    @include md-up {
      width: 70%;
      margin-top: -10px;
      margin-bottom: calc( var(--cell-y) * 1);
      font-size: pixel-calc(46, 61, $desktop-point); }

    @include breakpoint($max-width) {
      font-size: 61px;
      letter-spacing: 0.68px; }
    a {
      color: inherit;
      line-height: inherit; } }

  @include breakpoint-max($md-point) {
    &--2 &__content {
      padding-left: calc(var(--cell-x) * 2);
      padding-right: calc(var(--cell-x) * 2 + var(--mob-img-width)); }

    &--3 &__content {
      position: static; }
    &--3 &__title {
      position: absolute;
      top: 0;
      left: calc(var(--cell-x) * 2); }
    &--3 &__list {
      padding-top: 14vw; } }

  @include md-up {
    &--2 &__content {
      padding: 0; }
    &--4 &__content {
      padding-top: calc( var(--cell-y) * 5);
      width: calc( var(--cell-x) * 7); }
    &--3 &__title {
      margin-bottom: calc( var(--cell-y) * 2); } }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    @include md-up {
      margin-left: 22.34%;
      width: 60%; } }

  &--4 &__list {
    margin-left: 0; }

  &__text {
    opacity: 0;
    visibility: hidden;
    transition: all 0.12s $v--standard-easing;

    &.visible {
      opacity: 1;
      visibility: visible; } }

  &__alfa-text {
    bottom: pixel-calc(-42, -60, $desktop-point)!important;
    right: 0;
    width: 100vw;
    overflow: hidden;
    z-index: z('default');
    line-height: 1.05;

    &--darken {
      color: #928381; } }

  &--1 &__alfa-text {
    @include adaptive-size('font-size', 302); }
  &--2 &__alfa-text {
    @include adaptive-size('font-size', 338);
    transform: translateX(14px); }
  &--3 &__alfa-text {
    @include adaptive-size('font-size', 323); }

  &__list-item {
    @include appearance(none);
    position: relative;
    width: 100%;
    @include adaptive-size('padding-top', 9);
    @include adaptive-size('padding-bottom', 9);
    background-color: transparent;
    border: none;
    color: inherit;
    @include f-muller-reg;
    font-size: 5vw;
    @include breakpoint-max($md-point) {
      .home-page & {
        font-size: 4.0625vw; } }
    @include md-up {
      font-size: pixel-calc(15, 20, $desktop-point); }
    line-height: 1;
    text-align: left;
    z-index: z('above') + 2;

    @include breakpoint-max($md-point) {
      display: flex;
      & + & {
        margin-top: 4.6875vw; } }

    > span {
      pointer-events: none;
      &::before {
        top: .5em;
        @include sm-up {
          top: -2px; } } }

    &:hover, &:focus {
      @include f-muller-bold; }

    &:focus {
      outline: none; }

    &--bullet {
      text-transform: uppercase;
      line-height: 1.19;

      @include md-up {
        padding-left: pixel-calc(25, 28, $desktop-point); } }

    @include xs-only {
      &--bullet + &:not(&--bullet) {
        margin-top: 8vw; } }

    &.mb-6 {
      @include adaptive-size('margin-bottom', 32); } }

  &--4 &__list-item--bullet {
    padding-left: 0; }

  &__list-line,
  &__list-arrow {
    display: none;
    position: absolute;
    pointer-events: none;
    opacity: 0;
    transition: all 0.12s $v--standard-easing; }

  &__list-line {
    background-color: #1e0e00;
    @include md-up {
      display: flex;
      top: 20%;
      left: 0;
      height: 1px;
      width: pixel-calc(120, 160, $desktop-point);
      transform: translateY(-50%); } }

  &--2 &__list-line,
  &--4 &__list-line {
    left: unset;
    right: 0; }

  &__list-arrow {
    @include md-up {
      display: block;
      @include triangle(pixel-calc(19, 26, $desktop-point), #8d8280, 'right');
      top: 20%;
      left: 24%; } }

  &--2 &__list-arrow {
    left: 17%; }
  &--4 &__list-arrow {
    left: 0; } }
