.hero-section {
  display: flex;
  @include adaptive-size('padding-top', 96, $hasLimit: false);
  background-color: var(--gray-lightest);
  color: #1e0e00;
  overflow: hidden;
  padding: 25vw calc(var(--cell-x) * 2);

  @include sm-up {
    padding: calc(var(--cell-y) * 3) 0 0; }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: z('default'); }

  &__title {
    margin-bottom: 13.75vw;
    @include f-vogue;
    font-size: 7.5vw;
    @include adaptive-size('font-size', 100, $hasLimit: true);
    font-weight: 500;
    line-height: 1.15;
    text-transform: uppercase;
    @include sm-up {
      margin-bottom: calc(var(--cell-y) * 1.2); } }

  &__subtitle {
    line-height: 1.25;
    body:not(.insights-page) & {
      @extend .visible-sm-up; } }

  @include xs-only {
    &--team .section__subtitle {
      padding-top: 9.375vw; } }

  &__additional {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    @include adaptive-size('min-height', 468, $hasLimit: false);
    @include sm-up {
      position: relative;
      background-color: var(--light-bg-color);
      padding-top: calc(var(--cell-y) * 2);
      padding-bottom: calc(var(--cell-y) * 2);
      padding-left: calc( var(--cell-x) * 5);
      margin-top: calc(var(--cell-y) * 4);

      .section__subtitle,
      .section__desc {
        position: relative;
        width: calc( var(--cell-x) * 7); } } }

  &__letter {
    color: var(--beige);
    @extend %bg-letter;
    @include bg-letter-mixins();
    top: -30vw;
    left: 0;
    @include sm-up {
      left: calc( var(--cell-x) * 1.6);
      top: calc(var(--cell-y) * -3);
      z-index: 0; } }

  @include xs-only {
    &--team &__letter {
      top: 0; }
    &--consolidation &__letter {
      top: -38vw; } }

  @include sm-up {
    &--structuring &__additional,
    &--team &__additional {
      padding-bottom: calc(var(--cell-y) * 3);
      .section__subtitle,
      .section__desc {
        width: calc( var(--cell-x) * 8); } }

    &--maintenance &__additional {
      .section__subtitle,
      .section__desc {
        width: calc( var(--cell-x) * 8); } }

    &--assets &__additional,
    &--team &__additional {
      margin-top: auto; }

    &--assets &__additional {
      padding-bottom: calc(var(--cell-y) * 4); } } }
