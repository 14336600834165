$md-point: 900;
$desktop-point: 1439;
$max-screen-width: 1920;

$small: 700px;
$medium: $md-point + 'px';
$large: $desktop-point + 'px';
$extra-large: 1600px;
$breakpoints: (sm: $small, md: $medium, lg: $large, xl: $extra-large);

$space-unit: 8px;

$v--ease-in: cubic-bezier(0.18, 0.89, 0.32, 1.28) !global;
// for removing elements from the screen
$v--ease-out: cubic-bezier(0, 0, 0.25, 1) !global;
// for changing on-screen states at a users's input.
$v--standard-easing: cubic-bezier(0.4, 0, 0.2, 1) !global;
// majority

$z-indexes: ('header': 300, 'modal': 200, 'banner': 150, 'sidebar': 120, 'dropdown': 100, 'above': 20, 'default': 1, 'below': -1);

$banner-spacing: calc(3% + 48px);
