.contacts {
  $border-thick: 3px;
  $border-color: #c9afa4;

  z-index: 0;

  &__menu-locations {
    color: var(--gray-lightest);
    @include sm-up {
      padding: 0 calc(var(--cell-x) / 2);
      font-size: pixel-calc(13, 16, $desktop-point);
      line-height: 1.1; }

    li + li {
      @include sm-up {
        margin-top: calc(var(--cell-y) * 1.15); } } }

  &__subtitle {
    width: calc( var(--cell-x) * 13); }

  &__locations {
    display: flex;
    flex-direction: column;

    @include sm-up {
      @include adaptive-size('margin-top', 132, $hasLimit: false); }

    .section__desc {
      line-height: 1.56;
      @include xs-only {
        &:not(.contacts__mailto) {
          font-size: 4.375vw;
          margin-top: 1em; } } }
    a {
      color: inherit; } }

  &__location-block {
    display: flex;
    padding: 0;
    @include adaptive-size('padding-top', 80, $hasLimit: true);
    @include adaptive-size('padding-bottom', 100, $hasLimit: true);

    @include xs-only {
      flex-direction: column; } }

  &__item + &__item {
    @include xs-only {
      margin-top: 15.625vw; } }

  &__mailto {
    display: inline-flex;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0;
    padding: 6px 3.125vw;
    transform: translate(-50%, -48%);
    @include f-muller-med;
    text-transform: uppercase;
    background-color: #efeae9;
    @include xs-only {
      font-size: 4.6875vw; }
    @include sm-up {
      padding: 6px pixel-calc(90, 122, $desktop-point); } }

  &__map-btn {
    margin-top: 5vw;
    @include adaptive-size('margin-top', 55, $hasLimit: true); }

  &__separator {
    position: relative;
    height: $border-thick;
    background-color: $border-color;

    @include sm-up {
      width: 76%;
      margin: 0 3%; }

    &::before, &::after {
      content: '';
      position: absolute;
      width: $border-thick;
      height: 2.8125vw;
      @include adaptive-size('height', 18, $hasLimit: true);
      background-color: $border-color; }

    &::after {
      right: 0; }

    &--bot {
      @include xs-only {
        margin-top: 25vw; }

      &::before, &::after {
        bottom: 0; } } } }
