.portrait-services {
  $border-thick: 2px;
  $border-color: #c9afa4;

  background: url('../images/portrait/logo.svg') right bottom/contain no-repeat;

  @include sm-up {
    width: calc( var(--cell-x) * 20);
    grid-template-columns: repeat(20, 1fr);
    background-size: 50vw auto; }

  &__title {
    grid-area: 4 / 3 / 6 / 15; }

  &__column {
    &.c1 {
      grid-area: 8 / 2 / 29 / 6; }
    &.c2 {
      grid-area: 8 / 7 / 29 / 12; }
    &.c3 {
      grid-area: 8 / 13 / 29 / 18; } }

  &__list {
    @include adaptive-size('margin-left', 30, $hasLimit: true); }

  &__assets {
    --asset-link-color: var(--brown-light);

    @include sm-up {
      grid-area: 18 / 14 / 29 / 18; }

    position: relative;
    padding-top: calc( var(--cell-y) * 0.8);
    color: var(--asset-link-color);

    &-line {
      position: absolute;
      left: -15%;
      width: 112%;
      height: $border-thick;
      background-color: $border-color;
      &::before, &::after {
        content: '';
        position: absolute;
        height: pixel-calc(13, 18);
        width: $border-thick;
        background-color: $border-color; }

      &::before {
        left: 0; }
      &::after {
        right: 0; }

      &--top,
      &--top::before,
      &--top::after {
        top: 0; }
      &--bot,
      &--bot::before,
      &--bot::after {
        bottom: 0; } }

    .section__subtitle {
      transform: translateX(-3%); }
    ul {
      position: relative;
      padding-bottom: calc( var(--cell-y) * 0.6); }

    li {
      margin-top: calc( var(--cell-y) * 0.30555);
      @include adaptive-size('font-size', 20, $hasLimit: false); }
    a {
      color: var(--asset-link-color);
      &:hover {
        --asset-link-color: var(--brown); } } } }
