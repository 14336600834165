.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  pointer-events: none;
  z-index: z('header');

  @include xs-only {
    height: 15.625vw;
    background-color: var(--gray-lightest); }

  @include sm-up {
    padding: pixel-calc(52, 70, $desktop-point) 0; }

  .wrap {
    display: flex;
    align-items: center;
    padding: 0 calc(var(--cell-x)*2);
    height: 100%;
    @include sm-up {
      padding: 0;
      justify-content: space-between; } }

  &__link {
    height: 5.625vw;
    pointer-events: auto;
    svg {
      height: 100%;
      width: auto; }

    @include xs-only {
      transform: translateX(calc(var(--cell-x)* -0.5));
      svg {
        fill: var(--logo-color); } }

    @include sm-up {
      height: pixel-calc(27, 36, $desktop-point);

      svg {
        fill: var(--light-theme-color);
        will-change: fill;
        transition: fill 0.1s $v--ease-in; }

      &.dark-theme svg {
        fill: var(--dark-theme-color); }
      &.milk-theme svg {
        fill: var(--milk-theme-color); }
      .is-menu-opened & svg {
        fill: var(--logo-color); } } }

  &__mobile-menu {
    border: none;
    border-radius: 50%;
    outline: none;
    @include xs-only {
      margin-left: auto;
      margin-right: 10px; }
    .burger-menu {
      pointer-events: auto; } }

  &__link,
  &__mobile-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    @include sm-up {
      width: calc(var(--cell-x) * 3); } }

 }  // burger =====================================

.burger__line {
  display: block;
  width: 100%;
  height: 4px;
  position: absolute;
  left: 0;
  transition: .3s all linear; }

.burger__line--first {
  top: 0; }

.burger__line--second,
.burger__line--third {
  top: 50%;
  transform: translateY(-50%); }

.burger__line--fourth {
  bottom: 0; }

.burger__active {
  .burger__line--first,
  .burger__line--fourth {
    opacity: 0; }

  .burger__line--second {
    transform: translateY(-50%) rotate(45deg); }

  .burger__line--third {
    transform: translateY(-50%) rotate(-45deg); } }
