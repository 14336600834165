 :root {
  --line-distance: 0px; }

.main-swiper {
  padding-top: calc(var(--cell-y) * 6);
  padding-bottom: calc(var(--cell-y) * 4);
  background-color: var(--gray-lightest);

  &__bg {
    position: absolute;
    width: auto;
    height: calc(var(--cell-y) * 38);
    top: 0;
    left: 50%;
    transform: translateX(-50%) translateY(calc(var(--cell-y) * 22)); }

  .scroll-bar-background {
    opacity: 0; }

  &__container {
    width: 100vw; }

  .swiper-button-next {
    transform: none;
    @include triangle(4.0625vw, #1e0e00, 'right');
    transform: translateY(80%) translateX(50%);
    top: 0;
    right: var(--cell-x);
    @include md-up {
      @include triangle(pixel-calc(19, 26, $desktop-point), #1e0e00, 'right');
      top: unset;
      bottom: 10px;
      left: 95.88%; }

    &::after {
      content: ''; } }

  .scroll-bar-background {
    z-index: z('default'); } }

.swiper-slide {
  display: flex;
  flex-direction: column;
  color: #1e0e00;
  line-height: 1.17;

  .main-swiper__container & {
    @include breakpoint-max($md-point) {
      padding-right: calc(var(--cell-x) * 2);
      padding-left: calc(var(--cell-x) * 2); } }

  &__title {
    @include f-vogue;
    font-size: 7.5vw;
    margin-bottom: 7vw;
    text-transform: uppercase;
    font-weight: 400; }

  &__button {
    margin-top: 6.5vw;
    @include adaptive-size('margin-top', 64, $hasLimit: true); }

  &__img-container {
    margin-top: 12vw; }

  @include breakpoint-max($md-point) {
    &__desc {
      font-size: 1.5rem; }
    .slide-result &__img-container {
      order: 2; } }

  @include md-up {
    flex-direction: row;

    &__title {
      position: absolute;
      z-index: z('above');
      margin-bottom: 0; }

    &__img-container {
      margin-top: 0;
      img {
        min-height: 100%;
        height: auto;
        object-fit: cover; } }

    .slide-people &__desc {
      width: 44%;
      margin-left: 56%;
      @include adaptive-size('margin-top', 112, $hasLimit: true); }

    .slide-people &__img-container,
    .slide-result &__img-container {
      @include cont() {
        bottom: 0;
        @include adaptive-size('height', 2, $hasLimit: true);
        background-color: var(--brown-dark);
        width: 100%; } }

    .slide-people &__img-container {
      img {
        margin-top: 1px; }
      &::after {
        width: calc(100% + var(--line-distance)); } }

    .slide-result &__desc {
      width: 44%;
      margin-left: 44%;
      @include adaptive-size('margin-top', 213, $hasLimit: false);

      .section__desc {
        margin: 0; } }

    .slide-assets &__desc {
      width: 40%;
      margin-left: 40%;
      @include adaptive-size('margin-top', 213, $hasLimit: false);

      .section__desc {
        margin: 0; } }

    .slide-assets &__title {
      transform: translateX(-2%); } } }
