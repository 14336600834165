:root {
  --beige-lighter: #e8e1df;
  --beige-light: #d3c7c3;
  --beige: #b5aaa6;

  --brown-lighter: #cba28d;
  --brown-light: #915434;
  --brown: #372014;
  --brown-dark: #201600;
  --brown-darker: #1e0e00;

  --blue-light: #004b54;
  --blue: #002230;
  --blue-dark: #01090e;

  --gray-lightest: #ffffff;
  --gray-lighter: #f2f2f2;
  --gray-light: #cdcdcd;

  --light-theme-color: var(--blue-dark);
  --dark-theme-color: var(--gray-lightest);
  --milk-theme-color: var(--brown-light);

  --primary-text-color: var(--brown-darker);
  --light-bg-color: var(--beige-lighter);
  --border-color: #c9afa4;

  --logo-color: var(--light-theme-color);

  --burger-bg-color: rgba(255, 255, 255, 0.4);
}
