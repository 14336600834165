.hero-section--insights {
  background-color: white;
  @include breakpoint-max($md-point) {
    .hero-section__title {
      margin-bottom: 5vw; }
    .hero-section__subtitle {
      font-size: 3.5vw; } } }

#insights-list {
  @include sm-up {
    padding: calc(var(--cell-y)*2) 0; }
  @include md-up {
    padding: calc(var(--cell-y)*3) 0; }

  .list {
    display: grid;
    grid-gap: 2.5rem calc(var(--cell-x)*1);
    @include sm-only {
      grid-gap: 2.5rem calc(var(--cell-x)*1);
      grid-template-columns: repeat(2, 1fr); }
    @include md-up {
      grid-gap: calc(var(--cell-y)*3) calc(var(--cell-x)*1.2);
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, auto); } } }

.insights-pagination {
  --swiper-button-color: var(--primary-text-color);

  display: flex;
  justify-content: center;
  margin-top: calc(var(--cell-y)*2);

  li {
    a {
      position: relative;
      display: block;
      width: 2.1875vw;
      height: 2.1875vw;
      @include adaptive-size('height', 14, $hasLimit: true);
      @include adaptive-size('width', 14, $hasLimit: true);
      background-color: var(--swiper-button-color);
      border-radius: 50%;
      opacity: 1;
      margin-right: 0;
      margin-left: 0;
      transform: translate(-50%, -50%);
      font-size: 0;
      line-height: 0;

      @include cont() {
        @include xs-only {
          @include size(9.375vw); }
        @include circle(pixel-calc(42, 58));
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: transparent;
        border: 1px solid var(--swiper-button-color);
        opacity: 0;
        transition: .2s opacity, .2s background-color; }

      &:focus {
        outline: none;

        &::after {
          background-color: var(--swiper-button-color);
          opacity: 0.25; } } }

    &.active a::after {
      opacity: 1; }

    & + li {
      margin-left: 10vw;
      @include sm-up {
        margin-left: calc(var(--cell-x) * 0.825); } } } }

.insight {
  color: #1e0e00;
  font-family: Muller;
  font-size: 1rem;
  @include adaptive-size('font-size', 22, $hasLimit: true);
  line-height: 1.2;

  .link {
    color: inherit; }

  &__title {
    font-weight: 500;
    text-transform: uppercase; }

  &__desc {
    margin-top: 24px;
    @include adaptive-size('margin-top', 32, $hasLimit: false); }

  &__image {
    top: 0;
    max-width: 100%;
    background-color: #d7d1d6; }

  figure {
    position: relative;
    @include ratio-img(63, 100);
    margin-bottom: 32px;
    @include adaptive-size('margin-bottom', 48, $hasLimit: false); } }

.insights-alfa {
  @include breakpoint-max($md-point) {
    display: none;
    height: 0;
    width: 0;
    visibility: hidden; }

  position: absolute;
  width: 100%;
  @include adaptive-size('height', 800, $hasLimit: false);
  @include adaptive-size('top', 550, $hasLimit: false);
  background-color: var(--light-bg-color);
  z-index: 0;
  .alfa-text {
    line-height: 1.5;
    right: -8%; } }
