@mixin f-muller-reg {
  font-family: "Muller", sans-serif;
  font-weight: 400;
  font-style: normal; }

@mixin f-muller-med {
  font-family: "Muller", sans-serif;
  font-weight: 500;
  font-style: normal; }

@mixin f-muller-bold {
  font-family: "Muller", sans-serif;
  font-weight: bold;
  font-style: normal; }

@mixin f-vogue {
  font-family: "Vogue Highline Serif", serif; }

@mixin f-21-cent {
  font-family: "TwentyOneCent", serif; }

%bg-letter {
  display: block;
  position: absolute;
  left: calc(var(--cell-x)*2);
  top: 14.375vw;
  font-size: 102.1875vw;
  font-weight: 500;
  line-height: 0.8;
  z-index: z('below'); }

@mixin bg-letter-mixins() {
  @include f-vogue;
  @include adaptive-size('font-size', 655, $hasLimit: true);
  @include user-select(none); }

body {
  @include f-muller-reg;
  line-height: 1.5; }

a, .link,
button, .btn {
  line-height: 1.5; }
