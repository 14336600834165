.publication {
  display: flex;
  flex-direction: column;
  color: var(--brown-dark);

  &__date {
    @include f-muller-med;
    font-size: 4.0625vw;
    @include adaptive-size('font-size', 41, $hasLimit: true);
    text-transform: uppercase;
    letter-spacing: 0.45px;
    line-height: 1;
    margin-bottom: 1rem;
    @include md-up {
      margin-bottom: 30px; } }

  &__link {
    position: relative;
    color: #1e0e00;
    @include adaptive-size('margin-top', 72, $hasLimit: false);
    @include md-up {
      @include cont($isAfter: false) {
        position: absolute;
        top: 0;
        left: 30%;
        width: 3px;
        @include adaptive-size('height', 71, $hasLimit: false);
        background-color: var(--brown-dark);
        transform: translateY(-100%); } } }

  &__cover {
    width: 47.5vw;
    height: 0;
    padding-top: 130%;
    position: relative;
    margin-bottom: 48px;

    img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      object-fit: cover;
      object-position: top;
      border: 2px solid var(--brown-dark); }

    @include sm-up {
      width: 100%; }

    @include md-up {
      width: pixel-calc(168, 226, $desktop-point);
      height: pixel-calc(220, 294, $desktop-point);
      margin-bottom: pixel-calc(48, 65, $desktop-point);
      padding: 0;
      img {
        position: static; } }

    @include breakpoint($max-width) {
      width: 226px;
      height: 294px;
      margin-bottom: 65px; }

    @include cont($full: true) {
      top: 19px;
      left: 12px;
      background-color: var(--gray-light);
      @include md-up {
        top: 15px;
        left: 10px; } } }

  &__authors {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: -8px; }

  &__author-name,
  &__title,
  &__text {
    font-size: 3.5vw;
    line-height: 1.33;
    @include adaptive-size('font-size', 18, $hasLimit: true); }

  &__author-name {
    margin: 8px;
    padding: 8px 14px;
    background-color: var(--brown-dark);
    color: var(--gray-lightest);
    @include f-muller-bold;
    letter-spacing: 0.2px;
    box-decoration-break: clone;
    @include xs-only {
      line-height: 1; }

    @include md-up {
      padding: 3% 5%; } }

  &__author-name + &__author-name {
    margin-top: 0; }

  &__title {
    margin-top: 1.5rem;
    margin-bottom: 20px;
    @include adaptive-size('margin-top', 40, $hasLimit: true);
    @include adaptive-size('margin-bottom', 20, $hasLimit: true);
    @include f-muller-bold;
    text-transform: uppercase;
    @include md-up {
      margin-top: 2rem;
      line-height: 1.27; } }

  &__text {
    @include f-muller-reg;
    font-weight: 300;
    display: none;
    @include sm-up {
      display: block;
      font-weight: 400;
      line-height: 1.16; } } }
