.external-link {
  display: flex;
  align-items: flex-end;
  @include f-21-cent;
  text-transform: uppercase;
  color: inherit;
  &__icon {
    @include size(pixel-calc(27, 36, $desktop-point));
    @include adaptive-size('margin-right', 15, $hasLimit: false);
    &--dark {
      --external-link-icon-color: #000000; }
    &--light {
      --external-link-icon-color: var(--gray-lightest); } }
  &:hover, &:focus {
    font-weight: bold; } }
