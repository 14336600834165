.list {
  line-height: 1.19;

  @include xs-only {
    margin-top: 10vw;
    .maintenance-page .section__desc:not(.slider-3__desc) & {
      margin-top: 0; } }

  &__item {
    position: relative;

    @include xs-only {
      padding-left: calc(1.875vw + 2.5vw);
      & + & {
        margin-top: 1.25vw; } }

    @include cont('', false) {
      @include size(1.875vw);
      top: 0.4em;
      left: 0;
      background-color: currentColor;
      @include sm-up {
        $brk: unquote(str-slice(quote($small + ''), 1, -3));
        @include size(pixel-calc(6, 11, $brk));
        left: (pixel-calc(-9, -16, $brk));
        top: 0;
        transform: translate(-100%, 60%); } } }

  > .list {
    padding-left: var(--cell-x);
    margin: 2% 0;
    @include xs-only {
      padding-left: calc(var(--cell-x)*2);
      margin: 3% 0!important; } }

  &__link {
    display: block;
    margin-bottom: 4%;
    color: inherit;
    line-height: 1;
    text-transform: uppercase;

    &:hover, &:focus {
      font-weight: bold; } } }
