.contacts-page {
  background-color: #efeae9;

  &__text {
    min-height: calc( var(--cell-y) * 2);
    @include xs-only {
      font-size: 4.375vw;
      margin-bottom: 15.625vw; } }

  &__personal {
    display: flex;
    @include sm-up {
      margin-top: calc( var(--cell-y) * 2); }

    @include xs-only {
      width: 100%;
      flex-direction: column; } }

  &__link {
    display: flex;
    flex-direction: column;
    flex: 1; }

  &__link + &__link {
    @include xs-only {
      margin-top: 9.375vw;
      margin-bottom: 9.375vw; } }

  &__name {
    @include f-muller-med;
    text-transform: uppercase; }

  &__mail.section__desc {
    @include f-muller-reg;

    @include xs-only {
      margin-top: 0.5em;
      font-size: 4.6875vw; } } }
