.sidebar {
  --side-color: var(--light-theme-color);
  display: flex;
  position: fixed;
  width: calc(var(--cell-x) * 3);
  top: 0;
  left: 0;
  bottom: 0;
  padding: 0 pixel-calc(33, 44, $desktop-point) pixel-calc(40, 72) pixel-calc(33, 44, $desktop-point);
  z-index: z('sidebar');
  pointer-events: none;

  @include xs-only {
    height: 0;
    width: 0;
    display: none;
    visibility: hidden; }

  &.dark-theme {
    --side-color: var(--dark-theme-color); }
  &.milk-theme {
    --side-color: var(--milk-theme-color); }

  &__up-link {
    $triangle-size: pixel-calc(12, 16, $desktop-point);
    --triangle-color: var(--side-color);
    margin-top: auto;
    font-size: 0;
    user-select: none;
    pointer-events: auto;

    @include triangle($triangle-size, var(--triangle-color), 'up');
    transform: none;
    will-change: borderColor;
    transition: border-color 0.1s $v--ease-in; }

  &__nav {
    position: absolute;
    @include adaptive-size('bottom', 164, $hasLimit: false);
    padding-right: pixel-calc(33, 44, $desktop-point);
    pointer-events: auto;
    a {
      color: var(--side-color);
      will-change: color;
      transition: color 0.1s $v--ease-in; } } }
