.slider-3 {
  display: flex;
  overflow: hidden;

  &__content {
    height: 100%; }

  &__info {
    padding: 25vw calc(var(--cell-x)*2) 30vw;
    z-index: z('default'); }

  @include xs-only {
    &__info {
      order: 2; }

    .text-swiper-pagination {
      bottom: 15vw!important; } }

  @include sm-up {
    &__info {
      padding: 0;
      position: absolute;
      top: calc( var(--cell-y) * 10); }

    &__subtitle {
      margin-left: var(--cell-x);
      z-index: z('default'); }
    .list {
      padding-left: var(--cell-x); } } }
