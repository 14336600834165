.values {
  --section-bg: #915333;

  color: var(--gray-lightest);
  padding: 0;
  @include xs-only {
    background-color: var(--section-bg); }
  @include sm-up {
    height: calc( var(--cell-y) * 30);
    background-color: var(--gray-lightest); }

  &__title {
    @extend %mission-page-title;
    @include sm-up {
      margin-bottom: calc(var(--cell-y)*2); } }

  &__content {
    background-color: var(--section-bg);
    padding: 25vw calc(var(--cell-x)*2);

    @include sm-up {
      height: 100vh;
      padding: calc( var(--cell-y) * 3) calc( var(--cell-x) * 4);

      .section__desc {
        padding-top: calc( var(--cell-y) * 4);
        padding-left: calc( var(--cell-x) * 3); } } }

  &__bg-letter {
    --bg-letter-opacity: 0.06;
    @extend %bg-letter;
    @extend %mission-page-bg-letter;
    @include bg-letter-mixins;
    z-index: auto;
    @include sm-up {
      --bg-letter-top: calc(var(--cell-y)*5.5);
      transform: translateX(-2%);
      left: unset; } }

  &__image {
    img {
      height: 100%;
      object-fit: cover; }
    @include sm-up {
      position: absolute;
      top: calc( var(--cell-y) * 3);
      bottom: calc( var(--cell-y) * 3); } }

  &__list {
    .list__item + .list__item {
      margin-top: var( --cell-y); }

    @include xs-only {
      .list__item + .list__item {
        margin-top: 9.375vw; }
      .list__link {
        margin-bottom: 4.6875vw;
        font-weight: 500; } } } }
