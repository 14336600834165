// -----------
// Functions
// -----------

@function pixel-calc($min-size, $max-size, $min-vp-width: 900) {
  @return calc( #{$min-size}px + #{$max-size - $min-size} * ((100vw - #{$min-vp-width}px) / (#{$max-screen-width} - #{$min-vp-width})) ); }

@function pow($number, $exponent) {
  $value: 1;

  @if $exponent > 0 {
    @for $i from 1 through $exponent {
      $value: $value * $number; } }

  @return $value; }

@function z($layer) {
  @return map-get($z-indexes, $layer); }

// -----------
// Mixins
// -----------

@mixin box-sizing($boxsizing) {
  -webkit-box-sizing: $boxsizing;
  -moz-box-sizing: $boxsizing;
  box-sizing: $boxsizing; }

@mixin appearance($value) {
  -webkit-appearance: $value;
  -moz-appearance: $value;
  appearance: $value; }

@mixin user-select($prop) {
  -webkit-user-select: $prop;
  -moz-user-select: $prop;
  -ms-user-select: $prop;
  user-select: $prop;
  @if $prop == none {
    -webkit-touch-callout: $prop;
    cursor: default;
    pointer-events: none; } }

// Стилизация placeholder
// пример:
// @include placeholder {
//   color: grey;
//   font-size: 14px;
// };
@mixin placeholder {
  &::-webkit-input-placeholder {
    @content; }
  &::-moz-placeholder {
    @content; }
  &:-moz-placeholder {
    @content; }
  &:-ms-input-placeholder {
    @content; } }

@mixin size($w, $h:$w) {
  width: $w;
  height: $h; }

@mixin circle($w, $h:$w, $r: 50%) {
  @include size($w, $h);
  border-radius: $r; }

@mixin triangle($size, $color, $direction: 'down') {
  width: 0;
  height: 0;
  border: $size solid transparent;

  @if $direction == 'down' {
    border-top-color: $color;
    bottom: calc(#{$size} * 2 * -1);
    left: 50%;
    transform: translateX(-50%); }

  @else if $direction == 'up' {
    border-bottom-color: $color;
    top: calc(#{$size} * 2 * -1);
    left: 50%;
    transform: translate(-50%,1.5%); }

  @else if $direction == 'right' {
    border-left-color: $color;
    right: calc(#{$size} * 2 * -1);
    top: 50%;
    transform: translateY(-50%); }

  @else if $direction == 'left' {
    border-right-color: $color;
    left: calc(#{$size} * 2 * -1);
    top: 50%;
    transform: translateY(-50%); } }

// адаптивные размеры, зависящие от максимальной ширины контента
@mixin adaptive-size($property, $max-size, $min-size: round($max-size / $max-screen-width * $desktop-point), $hasLimit: true) {
  @if $hasLimit {
    @include sm-up {
      #{$property}: $min-size + px; }
    @include lg-up {
      #{$property}: pixel-calc($min-size, $max-size, $desktop-point); } }
  @else {
    #{$property}: pixel-calc($min-size, $max-size, $desktop-point); }

  @include breakpoint($max-width) {
    #{$property}: $max-size + px; } }


// aspect ratio image
@mixin ratio-img($h: 9, $w: 16) {
  height: 0;
  padding-top: calc(#{($h / $w)} * 100%);
  overflow: hidden;
  img {
    position: absolute;
    width: 100%;
    min-height: 100%;
    object-fit: cover;
    &.mid {
      object-position: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    &.bot {
      object-position: bottom;
      bottom: 0;
      top: unset;
      left: unset;
      transform: unset; }
    &.top {
      top: 0;
      left: 0;
      transform: unset;
      object-position: top; }
    &.custom {
      top: -95%;
      left: 0;
      width: 150%; } } }

// aspect ratio image + triangle
@mixin ratio-img-triangle($h: 9, $w: 16, $triangle-dir: 'down') {
  @include ratio-img($h, $w);

  @include cont() {
    @include triangle(8.125vw, var(--section-bg), $triangle-dir);
    @if $triangle-dir == 'up' {
      top: calc(100% - calc(8.125vw * 2)); }
    @else {
      top: -1px; } } }

@mixin section-info-triangle($dir: 'down') {
  @include triangle(8.125vw, var(--section-bg), $dir);
  @if $dir == 'up' {
    top: calc(8.125vw * -2); }
  @else {
    transform: translate(-50%, -1%); } }

// усечение элемента применять к текстовым тегам типа "span" "p" и т.д.
// пример: @include truncate(110px);
@mixin truncate($font-size, $line-height, $line: 1) {
  max-width: $line*$font-size*$line-height;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

// after||before content
@mixin cont($value:'', $isAfter: true, $full: false, $bothSides: false) {
  @if $bothSides {
    $isAfter: false;
    &::after, &::before {
      content: $value;
      display: block;
      position: absolute;
      @content; } }

  @else if $isAfter {
    &::after {
      content: $value;
      display: block;
      position: absolute;
      @if $full {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: z('below'); }
      @content; } }
  @else {
    &::before {
      content: $value;
      display: block;
      position: absolute;
      @if $full {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: z('below'); }
      @content; } } }

@mixin center($axis) {
  @if $axis == 'x' {
    left: 50%;
    transform: translateX(-50%); }
  @else if $axis == 'y' {
    top: 50%;
    transform: translateY(-50%); }
  @else {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); } }

@mixin hidden {
  visibility: hidden;
  position: absolute !important;
  display: none !important;
  width: 1px !important;
  height: 1px !important;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  // IE 6,7
  clip: rect(1px, 1px, 1px, 1px); }

@mixin opacity($opacity) {
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie);
  opacity: $opacity; }

@mixin column-size($col-num) {
  width: 100% * $col-num / $columns;
  flex-basis: 100% * $col-num / $columns;
  max-width: 100% * $col-num / $columns; }

@mixin text-accent($background: white) {
  $r: red($background)/255;
  $g: green($background)/255;
  $b: blue($background)/255;
  $gamma: 2.2;

  $luminosity: .2126*pow($r, $gamma) + .7152*pow($g, $gamma) + .0722*pow($b, $gamma);

  background: $background;
  color: if($luminosity < .5, $White, $text-color--regular); }

// padding for flexible side menu
@mixin pad-flex-menu {
  padding-left: 17%; }


@mixin alfa-text($color, $alfa: 0.1) {
  @include f-vogue;
  font-weight: 500;
  color: rgba($color, $alfa); }

// Rotated alfa text
@mixin rotated-alfa-text($fs, $color, $alfa: 0.1, $top: 98%) {
  display: block;
  position: absolute;
  top: $top;
  right: 0;
  transform-origin: top;
  transform: rotate(180deg);
  writing-mode: vertical-lr;
  overflow: hidden;
  font-size: $fs;
  line-height: 1.05;
  @include user-select(none);
  @include alfa-text($color, $alfa); }

// -----------
// Extend placeholders
// -----------
