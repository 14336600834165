.home-hero-section {
  height: 100vh;
  max-height: 648px;
  background-color: var(--gray-lightest);
  z-index: z('banner') + 1;

  @include md-up {
    min-height: unset;
    &, &__back-letter {
      height: calc(var(--cell-y) * 31); } }

  @include breakpoint-max($md-point) {
    display: flex;
    align-items: center;
    justify-content: center; }

  @media screen and (min-width: 600px) and (max-width: #{$medium}) {
    margin-top: 15.625vw; }

  .scroll-bar-background {
    opacity: 0; }

  &__back-letter {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    #c {
      @include f-vogue; }
    @include breakpoint-max($small) {
      width: calc(var(--cell-x) * 20);
      left: calc(var(--cell-x) * 2); } }

  &__text {
    background-color: var(--gray-lightest);
    border: 2px solid var(--blue);
    border-left: none;
    border-right: none;
    color: var(--blue);
    padding: 6.875vw 4.875vw;
    @include xs-only {
      margin-left: 20px;
      margin-right: 20px; }
    @include md-up {
      margin-top: calc(var(--cell-y) * 12);
      padding: 1.875% pixel-calc(48, 64, $desktop-point) 1.511%; }
    @include breakpoint($max-width) {
      padding-left: 64px;
      padding-right: 64px; } }

  &__title {
    @include f-vogue;
    font-size: 5.6vw;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1.1;
    @include md-up {
      font-size: pixel-calc(42, 58, $desktop-point);
      letter-spacing: 0.64px; }
    @include breakpoint($max-width) {
      font-size: 58px; } }

  &__desc {
    @include f-muller-reg;
    font-weight: 400;
    @include breakpoint-max($small) {
      margin-top: 5vw!important;
      font-size: 3.5vw; } } }
