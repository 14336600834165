.what-we-do {
  --grad-one: 35vw;
  --grad-two: 35vw;
  --grad-tree: 12.5vw;

  --line-one: calc(var(--cell-y) * 35);
  --line-two: calc(var(--cell-y) * 35);
  --line-tree: calc(var(--cell-y) * 9);

  padding-top: 20vw;

  @include md-up {
    --grad-one: calc(var(--cell-y) * 11);
    --grad-two: calc(var(--cell-y) * 11);
    --grad-tree: calc(var(--cell-y) * 10);

    padding-top: 0; }

  &.section {
    .scroll-bar-background {
      z-index: z('below'); } }

  &__main {
    align-items: stretch;
    @include breakpoint-max($md-point) {
      padding-right: calc(var(--cell-x) * 2);
      padding-left: calc(var(--cell-x) * 2);
      flex-direction: column; }
    @include md-up {
      padding-top: calc(var(--cell-y) * 8); } }

  &__main-text {
    display: flex;
    flex-direction: column;
    @include body-text;
    color: var(--gray-lightest);

    @include breakpoint-max($small) {
      font-size: 4.375vw;
      margin-top: 9.375vw; }

    @include md-up {
      border-left: 2px solid currentColor;
      padding-left: pixel-calc(60, 80, $desktop-point);
      margin-left: 7.761%;
      margin-bottom: 24px;

      .ru & {
        margin-top: calc(var(--cell-y) * 5.5); } } }

  &__en-text {
    letter-spacing: 0.04em;
    line-height: 1;
    color: #fff;
    @include f-vogue;
    font-size: 23vw;
    @include adaptive-size('font-size', 184, $hasLimit: true);
    &-sub {
      font-size: 20vw;
      @include adaptive-size('font-size', 172, $hasLimit: true); } }

  &__slides {
    .wwd-slide--1 {
      background: linear-gradient(180deg, rgba(#fff,0) 0%, rgba(#fff,0) var(--grad-one), #d9d2d0 var(--grad-one), #d9d2d0 100%); }
    .wwd-slide--2 {
      background: linear-gradient(180deg, #d9d2d0 0%, #d9d2d0 var(--grad-two), rgba(#fff,0) var(--grad-two), rgba(#fff,0) 100%); }
    .wwd-slide--3 {
      background: linear-gradient(180deg, rgba(#fff,0) 0%, rgba(#fff,0) var(--grad-tree), #d9d2d0 var(--grad-tree), #d9d2d0 100%); } }

  &__slides {
    width: 100%;
    @include md-up {
      width: unset; } }

  &__slides-container {
    position: relative; }

  &__decorative-bg {
    display: none;
    pointer-events: none;
    user-select: none;
    position: absolute;
    background: #0e3133 url('../images/bg_waves.jpeg') center right 100% no-repeat;
    background-size: cover;

    @include md-up {
      display: inline-block;

      &:nth-of-type(1) {
        top: var(--grad-one);
        height: var(--line-one); }

      &:nth-of-type(2) {
        top: calc(var(--cell-y) * 37);
        width: calc(var(--cell-x) / 2);
        height: var(--line-two); }

      &:nth-of-type(3) {
        bottom: 0;
        height: var(--line-tree); } } }

  &__slides {
    .wwd-slide {
      display: flex; } } }
