@mixin body-text {
  @include sm-only {
    font-size: 15px!important; }
  @include md-only {
    font-size: 16px!important; }

  line-height: 1.37;
  @include adaptive-size('font-size', 23, $hasLimit: true);
  @include sm-up {
    line-height: 1.19; } }


@include md-up {
  [data-section] {
    scroll-snap-align: start;
    // not supported in Safari and Firefox
    scroll-snap-stop: always;

    &.scroll-snap-auto {
      scroll-snap-stop: normal; } } }

[data-hidden-bar] {
  z-index: z('sidebar') + 1; }

@include xs-only {
  .mob-arrow {
    position: relative;
    z-index: z('default') + 1; }
  .mob-arrow:not(.mob-arrow--up) {
    @include cont() {
      @include section-info-triangle(); } }
  .alt-invest {
    &--venture, &--private, &--planning {
      .swiper-slide .alt-invest__info {
        @include cont() {
          @include section-info-triangle(); } } } }

  .mob-arrow--up, .slider-3__info {
    @include cont($isAfter: false) {
      @include section-info-triangle('up'); } } }

.section {
  height: auto;
  position: relative;

  @include xs-only {
    padding: 25vw 0; }

  @include orient-land() {
    min-height: 100vh !important; }

  &__subtitle {
    text-transform: uppercase;
    @include f-muller-med;
    @include xs-only {
      font-size: 4.6875vw; }
    @include sm-up {
      position: relative; } }

  &__sub-bg-letter {
    @extend %bg-letter;
    @include f-vogue;
    font-weight: 500;
    color: var(--letter-color, var(--beige));
    opacity: calc(var(--letter-opacity, 100) / 100);
    @include adaptive-size('font-size', 436, $hasLimit: true);

    @include xs-only {
      .maintenance-page & {
        font-size: 68.125vw;
        left: 0;
        right: 0;
        text-align: center; } }

    @include sm-up {
      left: 0;
      top: 86%;
      transform: translate(-50%, -50%); } }

  &__desc {
    @include f-muller-reg;
    @include xs-only {
      font-size: 4.375vw;
      [class*="title"] + &, {
        margin-top: 9.375vw; } }
    @include adaptive-size('margin-top', 20); }

  &__subtitle, &__desc {
    @include body-text; } }

.scroll-bar-background {
  display: none;
  @include md-up {
    @include orient-land() {
      display: block;
      position: absolute;
      top: 0;
      left: calc(var(--cell-x) * 3);
      width: 2px;
      height: 100%;
      background-color: var(--light-theme-color);
      transform: translateX(-1px); } } }

.asset-types, .all-services--shadow, .section-1-3 {
  .scroll-bar-background {
    z-index: z('default'); } }

.swiper-container ~ .scroll-bar-background {
  z-index: z('default'); }

[data-theme="dark"] .scroll-bar-background {
  background-color: var(--dark-theme-color); }
[data-theme="light"] .scroll-bar-background {
  background-color: var(--light-theme-color); }
