.history {
  --info-y-offset-cells: 3;
  @include xs-only {
    --info-y-offset-cells: 0 !important;
    padding-bottom: 0;
    .section__subtitle {
      position: relative; } }

  &__bg-year {
    position: absolute;
    left: 0;
    transform: translate(-50%, -50%);
    @include f-21-cent;
    font-size: 59.375vw;
    @include adaptive-size('font-size', 436, $hasLimit: true);
    font-weight: normal;
    letter-spacing: -0.14em;
    color: var(--year-color, #915333);
    opacity: var(--year-opacity, 0.2);
    @include user-select(none);
    z-index: z('below');

    @include xs-only {
      line-height: 0.45;
      right: 0;
      text-align: center;
      transform: none; }

    &:not(&--20) {
      margin-left: -8%; }
    &--20 {
      margin-left: -3%;
      letter-spacing: -0.07em; }

    @include sm-up {
      top: 70%; } }

  &__info > *:first-child {
    margin-top: calc(var(--info-y-offset-cells) * var(--cell-y)); } }
