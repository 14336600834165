.lang-switcher {
  pointer-events: auto;

  @include sm-up {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: var(--cell-x);
    height: calc(var(--cell-y) * 2); } }

.swith-to-lang {
  --lang-switch-color: var(--logo-color);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  font-size: 2.8125vw;
  @include adaptive-size('font-size', 18, $hasLimit: true);
  text-transform: uppercase;
  color: var(--lang-switch-color);

  @include xs-only {
    margin-right: -24px; }

  @include sm-up {
    padding: 4px;
    height: 100%;
    width: 100%;

    --lang-switch-color: var(--light-theme-color);

    .dark-theme & {
      --lang-switch-color: var(--dark-theme-color); }

    .is-menu-opened & {
      --lang-switch-color: var(--dark-theme-color); } } }
