
%mission-page-title {
  @include f-vogue;
  @include adaptive-size('font-size', 61, $hasLimit: false);
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  line-height: 0.85;
  @include xs-only {
    --title-color: var(--gray-lightest);
    font-size: 7.5vw;
    margin-bottom: 10.9375vw; } }

%mission-page-bg-letter {
  opacity: 0.1;
  line-height: var(--bg-letter-line-h, 1);
  color: var(--bg-letter-color, var(--gray-lightest));
  opacity: var(--bg-letter-opacity, 0.1);
  top: var(--bg-letter-top, 0);
  @include xs-only {
    --bg-letter-top: 15.625vw;
    --bg-letter-line-h: 0.8; } }
