.asset-types {

  &__first-screen,
  &__second-screen {
    flex-wrap: wrap; }

  @include md-up {
    &__first-screen &__block {
      &:nth-of-type(1) {
        min-height: calc(var(--cell-y)*10); }
      &:nth-of-type(2) {
        min-height: calc(var(--cell-y)*20); } }

    &__second-screen &__block {
      &:nth-of-type(1) {
        min-height: calc(var(--cell-y)*15); }
      &:nth-of-type(2) {
        min-height: calc(var(--cell-y)*15); } } }

  &__block {
    display: flex;
    align-items: center;
    padding: 12.5vw calc(var(--cell-x)*2) 12.5vw calc(var(--cell-x)*1.8);
    position: static;
    background-color: var(--block-bg, var(--light-bg-color));

    &--light {
      --block-bg: var(--gray-lightest); }

    @include sm-up {
      padding: calc(var(--cell-y)*2) calc(var(--cell-x)*2);
      .mr {
        margin-right: var(--cell-x); }
      .mr3 {
        margin-right: calc(var(--cell-x)*3); }
      .mr-1 {
        margin-right: calc(var(--cell-x) * -1); } }
    ul {
      position: relative;
      z-index: z('default') + 1; }

    @include xs-only {
      .list__item:before {
        top: .8em; } }

    .list__item + .list__item {
      margin-top: 10.75vw;
      @include sm-up {
        margin-top: calc(var(--cell-y)*1.2); } } } }
