@mixin xs-only {
  @media (max-width: #{$small - .02}) {
    @content; } }

@mixin sm-up {
  @media (min-width: #{$small}) {
    @content; } }

@mixin sm-only {
  $maxp: #{$md-point - .02}px;
  @media (min-width: #{$small}) and (max-width: $maxp) {
    content: 'aaaa';
    @content; } }

@mixin md-up {
  @media (min-width: #{$medium}) {
    @content; } }

@mixin md-only {
  $maxp: #{$desktop-point - .02}px;
  @media (min-width: #{$medium}) and (max-width: $maxp) {
    content: 'bbbb';
    @content; } }

@mixin lg-up {
  @media (min-width: #{$large}) {
    @content; } }

@mixin lg-only {
  $maxp: $extra_large - .02;
  @media (min-width: #{$large}) and (max-width: #{$maxp}) {
    @content; } }

@mixin xl-up {
  @media (min-width: #{$extra_large}) {
    @content; } }

@mixin breakpoint($point) {
  @media screen and (min-width: $point) {
    @content; } }

@mixin breakpoint-device($point) {
  @media screen and (min-width: $point) {
    @content; } }

@mixin breakpoint-max($point) {
  $maxp: $point - .02px;
  // @media screen and (max-width: #{$point})
  @media screen and (max-width: #{$maxp}) {
    @content; } }

@mixin orient-land() {
  @media screen and (orientation: landscape) {
    @content; } }

// grid functions

$grid-breakpoints: $breakpoints;
$column-gutter-breakpoints: (xs: 8px, sm: 16px);
$grid-gutter-breakpoints: (xs: 24px, md: 6%, xl: 'auto');

@function breakpoint($breakpoint) {
  @if map-has-key($grid-breakpoints, $breakpoint) {
    @return map-get($grid-breakpoints, $breakpoint); }
  @else {
    @warn "breakpoint: could not find #{$breakpoint} in $grid-breakpoints map. Please make sure it is defined"; } }

@function column-gutter($breakpoint) {
  @if map-has-key($column-gutter-breakpoints, $breakpoint) {
    @return map-get($column-gutter-breakpoints, $breakpoint); }
  @else {
    @warn "gutter: could not find #{$breakpoint} in $gutter-breakpoints map. Please make sure it is defined"; } }

@function grid-gutter($breakpoint) {
  @if map-has-key($grid-gutter-breakpoints, $breakpoint) {
    @return map-get($grid-gutter-breakpoints, $breakpoint); }
  @else {
    @warn "grid-gutter: could not find #{$breakpoint} in $grid-breakpoints map. Please make sure it is defined"; } }
