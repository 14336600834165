@mixin image-triangle($dir: 'right', $translateX: -2%) {
  @include cont($isAfter: true) {
    @include triangle(pixel-calc(36, 48, $desktop-point), var(--section-bg), $dir);
    left: 0;
    transform: translate($translateX, -50%);
    @content; } }

.alt-invest {
  --section-bg: var(--blue);
  --text-color: var(--gray-lightest);
  --letter-color: rgba(255, 255, 255, 0.1);
  --swiper-button-color: var(--beige-lighter);

  display: flex;
  background-color: var(--section-bg);

  @include xs-only {
    padding: 0;

    .alt-invest-page & .grid__row {
      flex-direction: column;
      justify-content: space-between; } }

  @include sm-up {
    background-color: var(--gray-lightest);

    &--private .swiper-slide {
      @include cont($isAfter: false) {
        left: 0;
        height: 100%;
        width: calc( var(--cell-x) * 17);
        background-color: var(--section-bg); } } }

  &--venture {
    --section-bg: var(--light-bg-color);
    --text-color: var(--primary-text-color);
    --letter-color: #a0a6a9;
    --swiper-button-color: var(--blue);

    @include sm-up {
      .swiper-slide {
        @include cont($isAfter: false) {
          left: calc( var(--cell-x) * 8);
          height: 100%;
          width: calc( var(--cell-x) * 16);
          background-color: var(--section-bg); } } } }

  &--collections {
    --letter-color: var(--blue-light);
    background-color: transparent;

    .swiper-container {
      width: 100vw;
      z-index: unset;

      .text-swiper-buttons {
        transform: translateY(-25%); } } }

  &--planning {
    --section-bg: var(--blue-light);

    @include sm-up {
      .swiper-slide {
        @include cont($isAfter: false) {
          left: 0;
          height: 100%;
          width: calc( var(--cell-x) * 17);
          background-color: var(--section-bg); } } } }

  &--independence {
    --section-bg: var(--blue); }

  &--long-term {
    --section-bg: var(--blue-light);
    --text-color: #e9f0f0;
    --letter-color: #195d65; }

  &--quality {
    --section-bg: #915333;
    --letter-color: rgba(255, 255, 255, 0.15); }

  &--reliability {
    --section-bg: var(--brown-lighter);
    --text-color: #251507;
    --letter-color: #d5b5a4; }

  &--comfort {
    --section-bg: var(--blue); }

  &--independence,
  &--quality,
  &--comfort {
    @include sm-up {
      @include cont($isAfter: false) {
        left: 0;
        height: 100%;
        width: calc( var(--cell-x) * 16);
        background-color: var(--section-bg); } } }

  &--team {
    --section-bg: var(--brown-light);
    --info-y-offset-cells: 0;
    @include sm-up {
      @include cont($isAfter: false) {
        left: 0;
        height: 100%;
        width: calc( var(--cell-x) * 15);
        background-color: var(--section-bg); } } }

  &--long-term,
  &--reliability {
    @include sm-up {
      [class*="__image-container"] {
        position: absolute; }
      @include cont($isAfter: false) {
        left: calc( var(--cell-x) * 8);
        height: 100%;
        width: calc( var(--cell-x) * 16);
        background-color: var(--section-bg); } } }

  &--preparing {
    --text-color: var(--primary-text-color);
    --letter-color: #b69485;
    --swiper-button-color: var(--primary-text-color);
    @include xs-only {
      .bg-image {
        right: -72%;
        width: auto; } } }

  &--education {
    --section-bg: var(--light-bg-color);
    --text-color: var(--primary-text-color);
    --letter-color: #caafa3;
    --swiper-button-color: var(--primary-text-color);

    .swiper-container {
      z-index: unset; }

    @include sm-up {
      .swiper-slide {
        @include cont($isAfter: false) {
          left: calc( var(--cell-x) * 9);
          height: 100%;
          width: 100%;
          background-color: var(--section-bg); } } } }

  &--independence,
  &--long-term,
  &--quality,
  &--reliability,
  &--comfort {
    --img-width-cells: 7;
    --img-height-cells: 21;
    --img-top-cells: 4; }

  &--people {
    --section-bg: #d7e1f0;
    --text-color: var(--primary-text-color);
    --letter-color: #95afbe;
    background-color: transparent; }

  &--people {
    --info-y-offset-cells: 6; }

  &--result,
  &--reputation {
    --info-y-offset-cells: 5; }

  &--people,
  &--result,
  &--reputation {
    .section__sub-bg-letter {
      @include xs-only {
        font-size: 68.125vw;
        left: 0;
        right: 0;
        text-align: center; }
      @include sm-up {
        top: unset;
        transform: translate(-50%,-47%); } } }

  &--result, &--history {
    --section-bg: #915333 !important;
    background-color: var(--section-bg);

    @include sm-up {
      background-color: transparent;
      @include cont($isAfter: false) {
        left: 0;
        height: 100%;
        background-color: var(--section-bg); }
      @include cont() {
        @include triangle(pixel-calc(36, 48, $desktop-point), var(--section-bg), 'right'); } } }

  &--reputation {
    --letter-color: #e7dcd8;
    --text-color: var(--primary-text-color); }

  &--result {
    --letter-color: #864a2c;
    --info-width-cells: 7;
    @include sm-up {
      &::before {
        width: calc( var(--cell-x) * 14); }
      &::after {
        left: calc( var(--cell-x) * 14); } } }

  &--history {
    &::before {
      width: calc( var(--cell-x) * 15); }
    &::after {
      left: calc( var(--cell-x) * 15); } }

  &--portrait {
    --section-bg: var(--light-bg-color);
    --text-color: var(--primary-text-color);
    z-index: 1;

    @include cont($isAfter: false) {
      left: 0;
      height: 100%;
      width: calc( var(--cell-x) * 13);
      background-color: var(--section-bg); } }

  &__info {
    color: var(--text-color);
    z-index: z('default');
    @include xs-only {
      padding: 25vw calc(var(--cell-x) * 2);
      .swiper-container & {
        padding-bottom: 42.1875vw; } }
    @include sm-up {
      margin-top: calc(var(--cell-y) * var(--info-y-offset-cells, 8)); } }

  &--team &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    & > p {
      margin-right: -20px;
      letter-spacing: -0.4px; } }

  &__title {
    margin-bottom: 12.5vw;
    font-size: 7.5vw;
    line-height: 1.3;
    font-weight: 400;
    text-transform: uppercase;
    @include f-21-cent;
    @include adaptive-size('font-size', 30, $hasLimit: true);
    @include sm-up {
      position: relative;
      line-height: 1.5;
      margin-bottom: calc( var(--cell-y) * 1.2); } }

  &__bg-letter {
    @extend %bg-letter;
    @include bg-letter-mixins;

    color: var(--letter-color);

    @include sm-up {
      left: calc( var(--cell-x) * -3);
      top: calc( var(--cell-y) * -4); } }

  &__subtitle {
    text-transform: uppercase;
    z-index: auto; }

  &__desc > .list {
    @include adaptive-size('margin-top', 19, $hasLimit: true);
    @include adaptive-size('padding-left', 29, $hasLimit: true); }

  &__image-container,
  &__full-height-img-container,
  &__bg-image-container {
    img {
      height: 100%;
      object-fit: cover; } }

  &__image-container {
    position: relative;

    @include xs-only {
      order: 2;
      @include ratio-img(3, 4);

      #succession-fam-img {
        width: 130vw;
        left: 0;
        transform: translateY(-50%); } }

    .swiper-container-autoheight & {
      transform: translateY(1px); }

    @include sm-up {
      z-index: z('above') + 1;
      width: calc(var(--cell-x) * 6);
      height: calc(var(--cell-y) * 20);
      top: calc( var(--cell-y) * 4); } }

  &__full-height-img-container {
    position: relative;
    height: calc( var(--cell-y) * var(--img-height-cells, 30));
    z-index: z('above') + 1;

    @include xs-only {
      @include ratio-img(3, 4);
      [class*="--portrait"] & {
        padding-top: 110vw;
        img {
          width: 130vw;
          right: 0;
          left: unset; } } } }

  @include sm-up {
    &--private &__image-container,
    &--planning &__image-container,
    &--independence &__image-container,
    &--quality &__image-container,
    &--comfort &__image-container,
    &--team &__image-container {
      @include image-triangle(); }

    &--venture &__image-container,
    &--long-term &__image-container,
    &--reliability &__image-container,
    &--education &__full-height-img-container,
    &--history &__full-height-img-container {
      @include image-triangle('left', 2%) {
        left: unset;
        right: 0; } } }

  &__bg-image-container {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: z('below');

    @include orient-land() {
      height: 100vh; }

    @include xs-only {
      @include cont('', $full: true) {
        background: linear-gradient(to right, rgba(0, 0, 0, var(--shadow-opacity, 0.4)), transparent);
        z-index: z('default'); } }

    > img {
      object-position: left; }

    .triangle-shape {
      display: none; }

    @include sm-up {

      > img {
        width: calc( 100vw - var(--cell-x) * 2); }

      .triangle-shape {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        z-index: z('default');
        width: calc( var(--cell-x) * 3); } } } }
