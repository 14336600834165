.key-section {
  --section-bg: var(--blue);

  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0;

  @include sm-up {
    flex-direction: row;

    @include orient-land() {
      & > [class*="-side"] {
        min-height: 100vh;
        position: relative; } } }

  & .grid__row {
    height: 100%; }

  &__small-right-side {
    z-index: 1;
    @include sm-up {
      padding-top: calc(var(--cell-y) * 4);
      flex: 1;
      background-color: var(--gray-lightest); } }

  &__img-container {
    position: relative;
    width: 100%;
    z-index: z('above') + 1;

    @include xs-only {
      @include ratio-img(3, 4);
      .key-section--asset-types & {
        overflow: visible;
        padding: 0; } }

    @include sm-up {
      transform: translateX(calc(var(--cell-x) * -1));
      width: calc(var(--cell-x) * 6);
      height: calc(var(--cell-y) * 20);
      @include cont() {
        @include triangle(pixel-calc(36, 48, $desktop-point), var(--section-bg), 'right');
        left: -2px; } } }

  &__image {
    display: block;

    @include sm-up {
      width: 100%;
      height: auto;
      min-height: 100%;
      object-fit: cover; } }

  &__main-left-side {
    width: 100%;
    padding: 25vw calc(var(--cell-x) * 2);
    background-color: var(--section-bg);
    color: var(--gray-lightest);
    @include sm-up {
      width: calc(var(--cell-x) * 17);
      padding: 0; } }

  &__bg-letter {
    @extend %bg-letter;
    @include bg-letter-mixins;
    z-index: z('default');
    color: var(--gray-lightest);
    opacity: 0.1;
    @include sm-up {
      top: calc( var(--cell-y) * 4);
      left: calc( var(--cell-x) * 3); }
    @include sm-only {
      font-size: 380px; }
    @include md-only {
      font-size: 420px; }
    @include md-up {
      top: calc( var(--cell-y) * 4);
      left: calc( var(--cell-x) * 5); } }

  &--consulting &__bg-letter,
  &--real-estate &__bg-letter {
    transform: translateX(-4%); }

  &__info {
    position: relative;
    @include sm-up {
      width: calc( var(--cell-x) * 8);
      margin-top: calc( var(--cell-y) * 6);
      margin-left: calc( var(--cell-x) * 5.5);
      margin-bottom: calc( var(--cell-y) * 3);
      padding-bottom: var(--cell-y);
      z-index: z('above'); }
    @include md-up {
      margin-top: calc( var(--cell-y) * 8);
      margin-left: calc( var(--cell-x) * 6); }
    @include lg-up {
      width: calc( var(--cell-x) * 6);
      margin-left: calc( var(--cell-x) * 8); } }

  &__target-text, &__title {
    @include f-21-cent;
    font-size: 7.5vw;
    @include adaptive-size('font-size', 30);
    font-weight: 400;
    line-height: 1.53;
    text-transform: uppercase;

    @include xs-only {
      line-height: 1.3; } }

  @include xs-only {
    &__target-text + &__second-title {
      margin-top: 13.75vw; } }

  @include sm-up {

    &__second-title {
      margin-top: calc( var(--cell-y) * 1.2); }

    &--smaller &__image {
      max-width: unset;
      min-height: unset;
      max-height: unset;
      width: calc( var(--cell-x) * 7);
      height: calc( var(--cell-y) * 20); }

    &--smaller &__main-left-side {
      width: calc(var(--cell-x) * 16); }

    &--smaller &__info {
      margin-top: calc( var(--cell-y) * 9);
      margin-left: calc( var(--cell-x) * 7); }

    &--smaller &__bg-letter {
      top: calc(var(--cell-y)*4);
      left: calc(var(--cell-x)*4); }

    &--maintenance &__desc {
      margin-top: calc(var(--cell-y) * 1.5); }

    &--maintenance &__info {
      padding: var(--cell-y) 0;
      margin-top: 0;
      margin-left: calc( var(--cell-x) * 6);
      width: calc(var(--cell-x) * 10);
      .list {
        margin-top: var(--cell-y); } } }

  &--maintenance &__main-left-side {
    display: flex;
    align-items: center; } }
