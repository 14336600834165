.about-us {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  min-height: unset;
  color: var(--gray-lightest);
  z-index: z('default');

  @include xs-only {
    &:not(&--people) {
      padding-top: 95%;
      height: 0;
 } }      // max-height: 700px

  &__bg {
    object-fit: cover;
    min-height: inherit;
    @include xs-only {
      height: 100%;
      position: absolute;
      top: 0; } }

  &__title, &__text {
    position: absolute;
    text-transform: uppercase; }

  &__title {
    bottom: 20%;
    @include f-vogue;
    font-size: 7.5vw;
    font-weight: 500;
    line-height: 1.17;
    @include sm-up {
      width: 22%;
      bottom: calc(var(--cell-y) * 5);
      font-size: pixel-calc(56, 76, $desktop-point);
      letter-spacing: 0.83px; } }

  &__text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    top: 58%;
    padding: pixel-calc(36, 48, $desktop-point) pixel-calc(40, 52, $desktop-point) pixel-calc(34, 40, $desktop-point);
    background-color: var(--gray-lightest);
    border: 2px solid var(--blue);
    border-left: none;
    border-right: none;
    color: #1e0e00;
    @include f-21-cent;
    @include adaptive-size('font-size', 40, $hasLimit: true);
    font-weight: 400;
    line-height: 1.3;
    text-align: left; }

  &__sublink {
    margin-bottom: pixel-calc(22, 30, $desktop-point);
    @include f-muller-reg;
    @include adaptive-size('font-size', 20, $hasLimit: true);
    color: inherit;
    text-transform: none; }

  &__bg-letter {
    position: absolute;
    height: calc(var(--cell-y) * 22);
    bottom: 0;
    z-index: z('below');
    object-fit: contain;
    @include breakpoint-max($md-point) {
      top: 10vw;
      height: auto; } }

  &--people {
    --section-bg: var(--gray-lightest);

    @include breakpoint-max($md-point) {
      margin: 24vw 0;
      height: auto;
      padding: 0 20px;
      max-height: unset; }

    @include md-up {
      align-items: center;
      .grid {
        position: relative; } } }

  &--people &__img {
    object-fit: cover;
    height: 100%;
    @include breakpoint-max($md-point) {
      position: absolute;
      width: calc(var(--cell-x) * 6);
      top: 0;
      left: 0;
      z-index: z('below'); }
    @include md-up {
      height: calc(var(--cell-y) * 26); } }

  &--people &__text {
    @include breakpoint-max($md-point) {
      position: static;
      padding: 5.75vw 5vw;
      margin-top: 51.875vw;
      margin-bottom: 12.5vw;
      font-size: 4.6875vw;
      line-height: 1.2;
      a {
        margin-bottom: 5vw;
        font-size: 4.0625vw;
        line-height: 1; } } } }
