.publics {
  padding: calc( var(--cell-y) * 4) 0;
  color: var(--brown-dark);
  z-index: z('banner') + 1;

  &__inner {
    display: flex;
    flex-direction: column;
    @include adaptive-size('margin-top', 70, $hasLimit: false);
    @include md-up {
      flex-direction: row; }

    .publics-block {
      order: 1;
      position: relative;
      z-index: 1;
      @include md-up {
        width: 80%; } }

    .insights-block {
      order: 2;
      background-color: #d9d2d0;
      position: relative;
      z-index: 2;
      padding: 12vw calc(var(--cell-x) * 5);

      @include breakpoint-max($md-point) {
        margin-top: calc(var(--cell-y)*2);

        .mob-img {
          width: calc(var(--cell-x) * 3);
          height: 110%;
          position: absolute;
          bottom: 0;
          left: 0;
          overflow: hidden;
          img {
            height: 100%;
            width: auto;
            min-width: 100%;
            transform: translateX(-50%); } }

        .publication__date {
          @include f-vogue;
          font-size: 5.6875vw;
          margin: 0; }

        .publication__title {
          margin: 0;
          text-transform: none; }

        .publication__cover,
        .publication__authors {
          display: none; } }

      @include md-up {
        background-color: white;
        width: 20%;
        order: unset;
        padding: 0;
        .mob-img {
          display: none; }

        .publication {
          background-color: white;
          padding-right: calc(calc(20px + 10*(100vw - 1439px)/481)*2);
          @include adaptive-size('margin-top', -75, -65, $hasLimit: true); }

        .publication__link:before {
          content: none; }

        .publication__cover {
          z-index: 1;
          @include cont($isAfter: false) {
            width: 100%;
            height: 4px;
            background-color: var(--primary-text-color);
            top: calc(-56px + -18*(100vw - 1439px)/481); } } } } }

  &--all {
    padding: calc( var(--cell-y) * 1) 0;
    @include xs-only {
      padding-top: 0;
      padding-bottom: 0; } }

  &__image {
    min-height: 100%;
    object-fit: cover; }

  &__main-text {
    @include f-muller-med;
    @include adaptive-size('font-size', 25, $hasLimit: false);
    line-height: 1.8;
    text-transform: uppercase; }

  &__secondary-text {
    @include f-muller-reg;
    @include adaptive-size('font-size', 20, $hasLimit: false);
    line-height: 1.95;
    text-transform: uppercase; }

  &__link {
    position: absolute;
    bottom: 0;
    right: 0;
    width: calc(var(--cell-x) * 3.02);
    color: inherit;
    @include f-muller-bold;
    @include adaptive-size('font-size', 18, $hasLimit: false);
    font-weight: 400;
    line-height: 1.7;
    text-transform: uppercase; }

  &__title {
    margin-bottom: 10vw;
    padding: 7vw var(--cell-x);
    position: relative;
    width: 100%;
    background: #012733 url('../images/publics/publics-bg.jpeg');
    background-position: bottom right;
    @include f-vogue;
    font-size: 7.5vw;
    @include adaptive-size('font-size', 105, $hasLimit: true);
    font-weight: 500;
    color: var(--gray-lightest);
    line-height: 1;
    text-transform: uppercase;
    text-align: center; }

  @include sm-up {
    &__timeline {
      padding-bottom: calc(var(--cell-y) * 2); }
    &__title {
      margin-bottom: calc(var(--cell-y) * 4);
      padding: 2.5% 2.632% 2.13%;
      letter-spacing: 2.63px; } } }
