@import "../utils";

.nav-circle {
  --default-nav-bg-color: #476e77;
  --default-nav-text-color: #78858a;
  --active-nav-bg-color: #2d4e57;
  --active-nav-text-color: var(--blue);
  --nav-line-color: var(--blue);

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include sm-up {
    flex-direction: row; }

  &--light {
    --default-nav-bg-color: #7fa5a9;
    --active-nav-bg-color: #336f76;
    --active-nav-text-color: var(--blue-light);
    --nav-line-color: var(--blue-light); }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @include sm-up {
      width: calc(100% / 4); }

    &:not(&--active) &-icon {
      background-color: var(--default-nav-bg-color);

      &:hover, &:focus, &.is-hovered {
        background-color: var(--active-nav-bg-color); } }

    &:not(&--active) &-text {
      color: var(--default-nav-text-color);
      a:hover, a:focus, a.is-hovered {
        color: var(--active-nav-text-color); } }

    &--active &-text {
      color: var(--active-nav-text-color); }

    &-icon:hover ~ &-text > *:first-child {
      color: var(--active-nav-text-color); } }

  &__item-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    min-width: 60px;
    min-height: 60px;
    @include size(60px);
    @include f-vogue;
    font-size: 42px;
    color: var(--gray-lightest);
    background-color: var(--blue);
    border-radius: 50%;
    transition: all 0.12s $v--standard-easing;
    letter-spacing: -2.46px;

    @include md-up {
      @include size(pixel-calc(88, 116, $desktop-point));
      min-width: pixel-calc(88, 116, $desktop-point);
      min-height: pixel-calc(88, 116, $desktop-point);
      font-size: pixel-calc(64, 84, $desktop-point); }

    @include cont() {
      @include center('x');
      top: 88%;
      width: 2px;
      height: pixel-calc(48, 66, $desktop-point);
      background-color: var(--nav-line-color);
      z-index: 1; }

    &:focus, &:focus-within {
      outline: none; } }

  &__item-text {
    @include f-muller-bold;
    font-size: 4vw;
    text-transform: uppercase;
    transition: all 0.12s $v--standard-easing;
    width: 100%;

    @include sm-up {
      max-width: 238px;
      margin-top: pixel-calc(64, 90, $desktop-point);
      font-size: 14px; }

    @include lg-up {
      font-size: pixel-calc(14, 18, $desktop-point); }

    > a {
      display: block;
      color: inherit;
      line-height: 1.2;

      @include xs-only {
        padding: 3.125vw 0; }

      & + a {
        $space: 20px;
        margin-top: $space;
        position: relative;
        @include cont('•', false) {
          @include center('x');
          top: -$space;
          color: inherit; } } }

    &.aligned {
      display: flex;
      flex-direction: column; }

    ul {
      $border-thick: 1px;
      $border-color: var(--default-nav-bg-color);
      display: none;

      @include sm-up {
        display: block;
        align-self: center;
        position: relative;
        padding-bottom: pixel-calc(12, 17, $desktop-point);
        padding-right: pixel-calc(7, 10, $desktop-point);
        @include adaptive-size('padding-left', 30, $hasLimit: false);
        text-align: left;
        transform: translateX(12%);

        @include cont($isAfter: false) {
          top: 0;
          left: 0;
          bottom: 0;
          width: $border-thick;
          height: 100%;
          background-color: $border-color; }

        @include cont() {
          bottom: -1px;
          left: 0;
          width: pixel-calc(13, 18);
          height: $border-thick;
          background-color: $border-color; } }

      li {
        @include adaptive-size('margin-top', 18);
        line-height: 1;

        a {
          @include f-muller-reg;
          @include adaptive-size('font-size', 19, $hasLimit: false);
          font-size: pixel-calc(12, 19);
          text-transform: initial;
          line-height: 1;
          color: inherit; } } } } }
