.page-in-progress {
  min-height: 100vh;
  background: var(--gray-lightest) url('../images/what-we-do/what_we_do_bg.png') center no-repeat;
  background-size: auto 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: var(--dark-theme-color);

  &__title {
    @include f-vogue;
    @include adaptive-size('font-size', 42, $hasLimit: false);
    margin-top: 20px; }

  &__btn-group {
    display: flex;
    margin-top: 20px;
    margin-top: 20px;

    .button + .button {
      @include adaptive-size("margin-left", 20, $hasLimit: false); } } }
