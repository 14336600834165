.home-page-waves-section {
  z-index: z('banner');
  overflow: hidden;

  @include breakpoint-max($md-point) {
    &.main-swiper {
      padding-top: 10vw; } }

  @include md-up {
    height: calc(var(--cell-y) * 30); }

  .waves-bg {
    position: absolute;
    width: auto;
    height: calc(var(--cell-y) * 38);
    top: 0;
    left: 50%;
    min-width: 100%;
    object-fit: cover; }

  &.main-swiper {
    @include breakpoint-max($md-point) {
      background-color: transparent;
      overflow: visible;
      z-index: 300; }

    .waves-bg {
      display: none;
      @include md-up {
        display: block;
        transform: translateX(-50%) translateY(calc(var(--cell-y) * 22)); } } }

  &.what-we-do {
    @include breakpoint-max($md-point) {
      overflow: visible;
      display: flex;
      align-items: center;
      padding-top: 0;

      .waves-bg {
        transform: translateX(0) translateY(-22%);
        right: 0;
        left: unset;
        height: 155%; } }

    @include md-up {
      .waves-bg {
        transform: translateX(-50%) translateY(calc(var(--cell-y) * -8)); } } } }
