.team {
  display: flex;
  flex-direction: column;
  background-color: var(--section-bg);

  @include xs-only {
    --section-bg: var(--beige-lighter);
    z-index: 1;

    &:not(.partners) {
      @include cont($isAfter: false) {
        @include triangle(8.125vw, var(--section-bg), 'up'); } }

    &__member-name {
      font-size: 4.6875vw; }
    &__member-position,
    &__member-description {
      font-size: 4.0625vw; }

    &__header,
    &__member {
      padding: 0 calc(var(--cell-x) * 2); } }

  @include sm-up {
    padding-top: calc(var(--cell-y) * 2.7);
    overflow: hidden;
    &__list,
    &__member-description {
      padding-bottom: calc(var(--cell-y) * 2); } }

  &__header {
    flex: 0; }
  &__content {
    flex: 1;
    display: flex; }

  &__title {
    @include f-vogue;
    font-size: 7.5vw;
    @include adaptive-size('font-size', 61, $hasLimit: true);
    font-weight: 500;
    line-height: 1.2;
    text-transform: uppercase;
    @include sm-up {
      margin-bottom: calc(var(--cell-y) * 2);
      min-height: calc(var(--cell-y) * 2); } }

  &__list {
    min-height: 1px;
    height: 100%; }

  &__member {
    cursor: pointer;
    @include adaptive-size('font-size', 23, $hasLimit: false);
    & + & {
      margin-top: 4.6875vw; }
    @include xs-only {
      .list__item {
        &::before {
          top: 1.5em; } } }

    @include sm-up {
      width: calc(var(--cell-x)*5);
      & + & {
        margin-top: calc(var(--cell-y) * 0.7); } } }

  &__member-name {
    @include f-muller-med;
    text-transform: uppercase;
    @include xs-only {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        @include triangle(4.0625vw, var(--brown-darker), 'right');
        transform: translate(-50%, -50%);
        transition: transform 0.12s;

        .is-active & {
          transform: translate(-100%, -25%) rotate(90deg); } } } }

  &__member-position {
    margin-top: 4.0625vw;
    @include sm-up {
      margin-top: calc(var(--cell-y) * 0.4); } }

  &__desc {
    min-height: calc(100vh - calc(var(--cell-y) * 6.7));
    @include cont($isAfter: false) {
      left: calc(var(--cell-x) * -1);
      top: calc(var(--cell-y) * -1);
      bottom: calc(var(--cell-y) * 3);
      height: 100%;
      width: 2px;
      background-color: #1e0e00;
      z-index: unset;
      @media screen and (min-height: 700px) {
        height: calc(100vh - calc(var(--cell-y) * 8)); } } }

  &__member-description {
    line-height: 1.5;

    @include xs-only {
      font-size: 4.0625vw;
      transition: max-height 0.24s $v--standard-easing;
      overflow: hidden;
      max-height: 0;
      max-width: inherit;
      margin: 4.0625vw calc(var(--cell-x) * -2) 0;
      padding-right: calc(var(--cell-x) * 2);
      padding-left: calc(var(--cell-x) * 3);
      background-color: var(--beige-light);
      p:first-of-type {
        padding-top: 4.6875vw; }
      p:last-of-type {
        padding-bottom: 4.6875vw; } }

    @include sm-up {
      font-size: 14px;
      position: absolute;
      min-height: calc(100vh - var(--cell-y)*6.7);
      left: calc(var(--cell-x)*6);
      top: 0;
      bottom: 0;
      right: calc(var(--cell-x)* -1);
      padding-left: var(--cell-x);
      padding-right: calc(var(--cell-x) * 4);

      &.visible {
        visibility: visible;
        display: block; }
      &:not(.visible) {
        visibility: hidden;
        display: none; }

      @include cont($isAfter: false) {
        left: 0;
        top: calc(var(--cell-y) * -1);
        bottom: calc(var(--cell-y) * 3);
        height: 100%;
        width: 2px;
        background-color: #1e0e00;
        z-index: unset;
        @media screen and (min-height: 700px) {
          height: calc(100vh - calc(var(--cell-y) * 8)); } } }

    @include breakpoint-device($desktop-point) {
      font-size: pixel-calc(14, 20, $desktop-point); }
    @include breakpoint-device($max-width) {
      font-size: 20px; }

    .section__desc {
      margin-top: 0;
      margin-bottom: calc(var(--cell-y) * 0.7);
      @include f-muller-med; }

    p {
      position: relative;
      z-index: 1; }

    img {
      position: absolute;
      bottom: 0;
      max-height: 100%;
      right: 0;
      width: auto;
      z-index: 0;
      @include xs-only {
        display: none;
        visibility: hidden; } } }

  .arrow {
    @include triangle(pixel-calc(19, 26, $desktop-point), #1e0e00, 'right');
    top: 2%;
    left: 0;
    transform: translate(-150%, -52%);
    position: absolute;
    pointer-events: none;
    transition: all 0.12s $v--standard-easing;
    @include xs-only {
      display: none;
      visibility: hidden; } } }
