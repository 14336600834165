.succession {
  --section-bg: var(--light-bg-color);
  --text-color: #1e0e00;
  --letter-color: #caafa3;
  --swiper-button-color: #1e0e00;

  display: flex;

  &--education {
    --info-x-offset-cells: 13;

    .swiper-container {
      z-index: unset; }

    .swiper-slide {
      @include cont($isAfter: false) {
        left: calc( var(--cell-x) * 9);
        height: 100%;
        width: 100%;
        background-color: var(--section-bg); } } }

  &__img-container {
    img {
      height: 100%;
      object-fit: cover; } }

  &__img-container {
    position: relative;
    width: calc( var(--cell-x) * 9);
    min-height: 100vh;
    height: 100%; }

  &--education &__img-container {
    @include cont() {
      @include triangle(pixel-calc(36, 48, $desktop-point), var(--section-bg), 'left');
      left: unset;
      right: 0;
      transform: translate(2%, -50%); } } }
