.all-services {
  --section-bg: var(--gray-lightest);
  --section-text-color: var(--primary-text-color);
  --color-line: var(--blue);

  background-color: var(--section-bg);
  color: var(--section-text-color);

  @include xs-only {
    padding: 0; }

  &--excellence {
    padding-bottom: 0; }

  &--independence {
    @include sm-up {
      background-color: transparent; } }

  &--investment,
  &--portrait,
  &--solutions,
  &--contacts {
    padding: 0; }

  [class*='__desc'] {
    color: var(--section-text-color); }

  &__info {

    @include xs-only {
      width: 100%;
      padding: 25vw calc(var(--cell-x) * 2); }

    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: calc(var(--cell-y) * 3) 0;
    @include md-up {
      padding: var(--cell-y) 0; }
    position: relative;
    z-index: z('default');

    @include orient-land() {
      min-height: 100vh; } }

  &__desc-offset {
    margin-top: 22.5vw; }

  &__list {
    margin-top: 0; }

  @include sm-up {
    &--contacts &__info > * {
      width: calc(var(--cell-x) * 8); }

    &--independence &__info {
      padding-left: calc(var(--cell-x) * 2);
      padding-right: calc(var(--cell-x) * 2);
      background-color: var(--section-bg);
      @include cont() {
        @include triangle(pixel-calc(36, 48, $desktop-point), var(--section-bg), 'left'); } }

    &__desc {
      margin-top: pixel-calc(26, 34, $desktop-point); }
    &__desc-offset {
      margin-top: 3em; }
    &__list {
      margin-top: pixel-calc(22, 30, $desktop-point);
      margin-right: -24px; } }

  &__img-container {
    position: relative;

    @include xs-only {
      @include ratio-img(3, 4);

      width: 100%;
      img {
        bottom: 0;
        top: unset;
        left: unset;
        transform: unset; } }

    @include orient-land() {
      min-height: 100vh; }

    @include sm-up {
      overflow: hidden;
      img {
        height: 101%;
        object-fit: cover; }

      @include cont() {
        @include triangle(pixel-calc(36, 48, $desktop-point), var(--section-bg), 'right');
        left: -2px; } } }

  &--left &__img-container {
    @include sm-up {
      @include cont() {
        @include triangle(pixel-calc(36, 48, $desktop-point), var(--section-bg), 'left');
        left: unset;
        right: -2px; } } }

  @include xs-only {
    #history-18 &__img-container img {
      width: 140vw; }

    &--left &__img-container {
      order: 2; }

    #reputation &__img-container,
    &--contacts &__img-container,
    &--risk &__img-container {
      order: -1;
      @include ratio-img(3, 4);
 } }      // $triangle-dir: 'up'

  &--real-estate &__img-container img {
    object-position: left; }

  &__color-line {
    background-color: var(--color-line);
    @include xs-only {
      display: none;
      width: 0;
      height: 0; }
    @include sm-only {
      display: none; } }

  &--shadow {
    @include sm-up {
      @include cont('', false, true) {
        width: calc( var(--cell-x) * var(--shadow-cells, 7));
        right: 0;
        left: unset;
        background-image: linear-gradient(to left, rgba(0, 0, 0, var(--shadow-opacity, 0.4)), transparent);
        z-index: z('default');
        @include user-select(none); } } }

  &--left::before {
    left: 0;
    background-image: linear-gradient(to right, rgba(0, 0, 0, var(--shadow-opacity, 0.4)), transparent); }

  &--benefits &__img-container {
    width: calc( var(--cell-x) * 9);
    height: calc( var(--cell-y) * 16);
    top: calc( var(--cell-y) * 7); }

  .maintenance-page &__info .section__subtitle {
    margin-top: 0;
    @include adaptive-size('margin-top', 135, $hasLimit: true); } }
