.ss-wrapper {
  width: 100%;
  position: relative;
  z-index: z('banner');
  @include sm-up {
    overflow: hidden;
    height: 100vh;
    float: left; } }

.ss-content {
  height: 100%;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  &:not(.ss-grabbed) {
    scroll-behavior: smooth;
    @media screen and (min-height: 700px) {
      scroll-snap-type: y mandatory; } }

  @include sm-up {
    width: calc(100% + 18px);
    padding: 0 18px 0 0; }

  .base-scroll & {
    scroll-behavior: auto; }
  .scroll-none & {
    scroll-snap-type: none!important; }

  &.rtl {
    width: calc(100% + 18px);
    right: auto; } }

.ss-scroll {
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  width: 6px;
  border-radius: 5px;
  top: 0;
  right: 1.5px;
  z-index: z('banner');
  cursor: pointer;
  opacity: 0;
  will-change: width, opacity;
  transition: width 0.1s linear, opacity 0.2s linear;
  &:not(.scrollbar-thumb):hover {
    width: 9px;
    opacity: 1; }

  &.scrollbar-thumb {
    --scroll-color: var(--light-theme-color);
    display: none;
    @include md-up {
      @include orient-land() {
        display: block;
        position: fixed;
        left: calc(var(--cell-x) * 3);
        transform: translateX(-50%);
        background-color: var(--scroll-color);
        border-radius: 0;
        opacity: 1;
        z-index: z('banner') - 1;
        cursor: default; } } }

  &.dark-theme {
    --scroll-color: var(--dark-theme-color); }
  &.milk-theme {
    --scroll-color: var(--milk-theme-color); } }

.ss-hidden {
  display: none; }

.ss-container {
  &:hover .ss-scroll, &:active .ss-scroll {
    opacity: 1; } }

.ss-grabbed {
  -o-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none; }
