.section-1-3 {
  --img-width: 25%;
  --bg-color: var(--light-bg-color);
  background-color: var(--bg-color);
  color: #1e0e00;

  &__image {
    display: none; }

  &__info {
    padding: 0 calc(var(--cell-x)*2); }

  @include sm-up {
    padding-left: var(--img-width);
    min-height: 50vh;
    z-index: z('above') - 2;

    @include cont() {
      @include triangle(pixel-calc(36, 48, $desktop-point), var(--bg-color), 'left');
      left: calc(var(--img-width) + 0.2%);
      transform: translate(-100%, -50%); }

    &__info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 80%;
      margin: auto;
      width: 100%;
      height: 100%;
      min-height: 95vh;
      padding: 40px 0;
      position: relative; }

    &__image {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: var(--img-width);
      height: 100%;
      object-fit: cover; }

    &--management, &--consulting {
      @include cont('', false, true) {
        width: calc(var(--img-width) / 1.5);
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0.67), transparent);
        z-index: z('default');
        @include user-select(none); } } }

  @include md-up {
    --img-width: 33.33%;
    &__info {
      margin: unset;
      max-width: 40%;
      transform: translateX(50%); } } }
