$light-color: var(--gray-lightest);
$dark-color: #000000;
$hover-color: var(--gray-lightest);

@mixin margin-reset {
  margin: 0; }

@mixin list-reset {
  list-style: none;
  padding: 0;

  @include margin-reset; }

@mixin border-effect($border-width) {
  border-width: $border-width;
  border-style: solid;
  border-color: transparent;
  transition: all .2s $v--standard-easing; }

@mixin before {
  position: absolute;
  content: ""; }

.menu {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-image: url("../images/bg_waves.jpeg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transition: all 0.2s $v--ease-in;
  opacity: 0;
  z-index: -1;

  @include xs-only {
    overflow-y: auto; }

  &--active {
    opacity: 1;
    z-index: z('modal');
    transition-timing-function: $v--ease-out; }

  &:not(&--active) {
    visibility: hidden;
    user-select: none; }

  &__primary-list,
  &__secondary-list,
  &__tertiary-list,
  &__quaternary-list {
    @include list-reset; }

  &__quaternary-list {
    @extend .visible-sm-up; }

  &__tertiary-list {
    position: relative;
    @include xs-only {
      display: flex;
      flex-direction: column; } }

  &__arrow {
    @extend .visible-sm-up;
    position: absolute;
    @include triangle(pixel-calc(7, 10, $desktop-point), $light-color, 'right');
    top: 2%;
    left: 0;
    opacity: 0;
    transform: translate(100%, -60%);
    transition: all 0.18s $v--standard-easing; }

  .is-hovered &__arrow {
    opacity: 1; }

  &__wrapper {
    display: flex;
    min-height: 100%;
    width: 100%;
    z-index: 10;
    transition: all 0.2s $v--ease-in;
    @include xs-only {
      display: block;
      overflow-y: auto;
      background-color: rgba(#000, 0.5); }
    a {
      color: var(--gray-lightest);
      text-transform: uppercase; } }

  // White left line that contains logo
  &__block {
    display: none;
    background-color: $light-color;
    @include sm-up {
      display: block;
      justify-content: center;
      position: relative;
      width: calc(var(--cell-x) * 3);
      min-height: 1px;
      pointer-events: auto; }

    @include xs-only {
      align-items: center; } }

  &__logo {
    height: pixel-calc(27, 36, $desktop-point);
    width: auto;
    @include xs-only {
      transform: translateX(calc(var(--cell-x)*1.5));
      height: 6.25vw; } }

  &__navigation {
    display: flex;
    width: 100%;
    background-color: transparent;
    @include sm-up {
      width: calc(var(--cell-x) * 21);
      height: 100%; }

    @include xs-only {
      overflow: auto;
      padding: 15.625vw 0; }

    br {
      display: none;
      @include sm-up {
        display: inline; } } }
  // Lists

  &__primary-list {
    display: flex;
    flex-direction: column;

    @include sm-up {
      padding-top: var(--cell-y);
      margin-top: calc(var(--cell-y) * 4); }
    @include xs-only {
      width: 100%; } }

  &__secondary-list {
    align-items: flex-start;
    @include xs-only {
      transition: height 0.3s $v--standard-easing;
      overflow: hidden;
      height: 0; }

    @include sm-up {
      display: none;
      position: fixed;
      top: calc(var(--cell-y) * 4);
      left: calc(var(--cell-x) * 8);
      height: calc(var(--cell-y) * 22);
      padding: 0;
      transition: all 0.5s ease-in;

      &--show {
        display: flex;
        flex-direction: row; } }

    .contacts & {
      @extend .visible-sm-up; } }

  &__tertiary-list {
    @include xs-only {
      padding: 1.5625vw 0;
      margin-left: calc(var(--cell-x)*2);
      background-color: rgba(#000, 0.5);
      .menu__link + & {
        padding: 0;
        transition: height 0.3s $v--standard-easing;
        overflow: hidden;
        height: 0;
        li:first-of-type {
          padding-top: 1.5625vw; }
        li:last-of-type {
          padding-bottom: 1.5625vw; } } } }

  // Links

  &__link {
    transition: all 0.2s $v--standard-easing;
    &:hover, &.is-current {
      color: $hover-color; } }

  &__primary-link,
  &__secondary-link,
  &__tertiary-link,
  &__quaternary-link {
    display: block;
    position: relative; }

  &__primary-link,
  &__secondary-link {
    @include f-21-cent; }

  &__tertiary-link,
  &__quaternary-link {
    @include f-muller-med;
    line-height: 1.3; }

  &__primary-link {
    padding: 3.125vw calc(var(--cell-x) * 2);
    line-height: 1;
    font-size: 4.6875vw;
    @include adaptive-size('font-size', 30, $hasLimit: true);
    @include xs-only {
      position: relative; }

    @include sm-up {
      padding: var(--cell-y) 0 var(--cell-y) var(--cell-x);
      width: calc(var(--cell-x) * 5);
      line-height: 1.2; } }

  &__secondary-link {
    padding: 1.5625vw 0;
    margin: 1.5625vw calc(var(--cell-x) * 2);
    font-size: 3.75vw;
    @include adaptive-size('font-size', 23, $hasLimit: true);
    @include xs-only {
      display: inline-block; }
    @include sm-up {
      margin: 0 0 var(--cell-y) 0;
      padding: 0 calc(var(--cell-x) / 2);
      line-height: 1.56; } }

  &__tertiary-link {
    padding: 1.5625vw 7.5vw;
    font-size: 2.8125vw;
    @include adaptive-size('font-size', 16, $hasLimit: true);
    @include sm-up {
      margin-bottom: pixel-calc(18, 24, $desktop-point);
      padding: 0 calc(var(--cell-x) / 2); } }

  &__quaternary-link {
    @include adaptive-size('font-size', 14, $hasLimit: true);
    @include sm-up {
      padding: pixel-calc(6, 9, $desktop-point) calc(var(--cell-x) / 4);
      margin: 0 calc(var(--cell-x) / 2); } }

  // Secondary lists dividers
  &__divider {
    @include sm-up {
      background-color: $light-color;
      width: 1px;
      height: 100%;
      margin-left: var(--cell-x);
      transform: translateX( calc(var(--cell-x) / -2)); } } }

// Arrow for menu__primary-link

.link__arrow {
  @include xs-only {
    position: absolute;
    width: 0;
    height: 0;
    border: 2.1875vw solid transparent;
    border-top-color: #fff;
    top: 50%;
    right: calc(var(--cell-x) * 2);
    bottom: 0;
    transform: translateY(-20%);
    transition: transform 0.18s;
    .is-active & {
      transform: scaleY(-1) translateY(80%); }

    .contacts & {
      display: none;
      visibility: hidden; } }

  @include sm-up {
    position: absolute;
    @include triangle(pixel-calc(38, 50, $desktop-point), $light-color, 'right');
    left: -1px;
    opacity: 0;
    &--active {
      opacity: 1; } } }

// List items

.primary-list {
  &__item {
    display: flex;
    flex-direction: column;

    @include xs-only {
      padding: 6.25vw 0;
      & + & {
        position: relative;
        @include cont() {
          top: 0;
          height: 1px;
          width: calc(100% - calc(var(--cell-x) * 4));
          margin: 0 calc(var(--cell-x) * 2);
          background-color: white; } } }

    @include sm-up {
      flex-direction: row; } } }

.secondary-list {

  &__item {
    padding: 0 calc(var(--cell-x) * 2);

    @include xs-only {
      position: relative;
      .menu__secondary-link {
        position: static;
        &::after {
          content: '';
          position: absolute;
          pointer-events: none;
          width: 0;
          height: 0;
          border: 1.25vw solid transparent;
          border-top-color: #fff;
          top: 0;
          right: calc(var(--cell-x) * 2);
          bottom: 0;
          transform: translate(-40%, 210%);
          transition: transform 0.18s; } }
      &.is-active .menu__secondary-link::after {
        transform: scaleY(-1) translate(-40%, -150%); } }

    @include sm-up {
      width: calc(var(--cell-x) * 4);
      height: calc(100% - var(--cell-y));
      margin: auto;
      padding: calc(var(--cell-y) * 1.5) 0;
      transition: all 0.2s $v--standard-easing;

      &.is-hovered {
        background-color: rgba(#000, 0.5); } } } }
