.page-nav {
  padding-top: calc( var(--cell-y) * 3);
  padding-bottom: calc( var(--cell-y) * 5);

  &--maintenance {
    @include cont($full: true) {
      left: unset;
      z-index: z('default');
      width: calc( var(--cell-x) * 9);
      background-color: var(--gray-lightest); } }

  &--benefits {
    background-color: var(--section-bg);
    padding-bottom: calc( var(--cell-y) * 3);
    color: #1e0e00; }

  &__title {
    @include f-vogue;
    @include adaptive-size('font-size', 61, $hasLimit: false);
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1.2;
    letter-spacing: 1.5px; }

  &--benefits &__title {
    @include xs-only {
      font-size: 7.5vw; }
    @include adaptive-size('font-size', 100, $hasLimit: false); }

  &__content {
    margin: 0 auto; }

  &__info {
    padding: calc( var(--cell-y) * 1.5) 0;
    @include adaptive-size('font-size', 23, $hasLimit: false); }

  &__list {
    margin-top: var(--cell-y);
    margin-left: var(--cell-x); }

  &__item {
    display: flex;

    @include xs-only {
      & + & {
        margin-top: 5.625vw; } }

    &::before {
      top: 0.3em;
      @include sm-up {
        top: 40%;
        transform: translate(-100%,-40%); } } }

  &__link {
    position: relative;
    width: 100%;
    padding: pixel-calc(9, 13, $desktop-point) 0;
    @include f-muller-reg;
    font-size: 4.375vw;
    @include adaptive-size('font-size', 23, $hasLimit: true);
    text-transform: uppercase;
    color: inherit;
    line-height: 1.2;
    @include sm-up {
      line-height: 1; }

    &:hover, &:focus {
      font-weight: bold; } }

  &__line {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    opacity: 0;
    transition: all 0.12s $v--standard-easing;
    z-index: z('above') - 1;
    @include user-select(none);

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 50%; }

    &::before {
      @include triangle(pixel-calc(19, 26, $desktop-point), var(--gray-lighter), 'right');
      left: 0; }

    &::after {
      right: calc( var(--cell-x) * 7);
      height: 1px;
      width: calc( var(--cell-x) * 2.6);
      background-color: var(--gray-lighter); }

    &:hover,
    &:focus {
      font-weight: bold;
      &::before,
      &::after {
        opacity: 1; } } }

  &__img-container {
    display: block;
    position: absolute;
    height: calc( var(--cell-y) * 21);
    top: calc( var(--cell-y) * 2);
    bottom: calc( var(--cell-y) * 4);
    left: calc( var(--cell-x) * 10);
    background-color: var(--section-bg);
    transition: all 0.2s $v--standard-easing;
    z-index: z('above');

    img {
      object-fit: cover;
      height: 100%; } }

  &--images &__list:hover ~ &__img-container {
    opacity: 0;
    visibility: hidden; }

  &--images &__link + &__img-container {
    opacity: 0;
    visibility: hidden; }

  &--images &__link:hover + &__img-container,
  &--images &__link:focus + &__img-container {
    opacity: 1;
    visibility: visible; } }
