.burger-menu {
  @include size(50px);
  display: flex;
  position: relative;
  border: 2px solid transparent;
  border-radius: 50%;
  cursor: pointer;
  background-color: var(--burger-bg-color);

  &:hover {
    box-shadow: 0 5px 16px -10px rgba(32, 22, 0, 0.24);
    border-color: var(--gray-lighter); }

  &:focus {
    outline: none;
    box-shadow: 0 5px 16px -10px rgba(32, 22, 0, 0.4); }

  &__buns {
    display: block;
    width: 25px;
    height: 19px;
    position: relative;
    margin: auto;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;

    span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background-color: var(--brown-dark);
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: 0.2s $v--standard-easing;

      &:nth-child(1) {
        top: 0px; }

      &:nth-child(2),
      &:nth-child(3) {
        top: 8px; }

      &:nth-child(1) {
        top: 16px; } } }

  &--active &__buns span {

    &:nth-child(1),
    &:nth-child(4) {
      top: 8px;
      width: 0%;
      left: 50%; }

    &:nth-child(2) {
      transform: rotate(45deg); }

    &:nth-child(3) {
      transform: rotate(-45deg); } } }
