* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &::before, &::after {
    box-sizing: inherit; } }

*:focus {
  outline-color: var(--blue-light); }

@include sm-up {
  html {
    overflow: hidden; }
  body {
    overflow-anchor: none;
    overflow: hidden;
    height: 100vh;
    background-color: var(--gray-lightest); } }

.is-menu-opened {
  &, .body {
    overflow: hidden; } }

ul,
nav {
  list-style: none; }

p > p {
  margin-top: 16px; }

img {
  display: block;
  width: 100%;
  height: auto;
  object-fit: contain;
  &.bg-image {
    position: absolute;
    width: 100%;
    height: calc(var(--cell-y) * 30);
    object-fit: cover;
    @include xs-only {
      height: 100%; } } }

a, .link,
button, .btn {
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
  will-change: background-color, border, box-shadow;
  transition-property: color, background-color, border, box-shadow;
  transition-timing-function: $v--standard-easing;
  transition-duration: .28s;
  &:not(:disabled) {
    cursor: pointer; } }

.btn-group {
  display: flex;
  flex-direction: column;

  @include sm-up {
    flex-direction: row; }

  & > .component {
    @include sm-up {
      &:not(:last-of-type) {
        margin-right: 16px; } } } }

.separator {
  width: 100%;
  height: 2px;
  display: inline-block;
  margin: 16px 0 24px; }

.nowrap {
  white-space: nowrap; }

.is-hidden {
  opacity: 0!important;
  @include user-select(none); }

.hidden-svg-def {
  position: absolute;
  height: 1px;
  width: 1px;
  transform: translate(-100%, -100%);
  user-select: none; }
